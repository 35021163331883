/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteProtection from './RouteProtection';

//ANAGRAFICHE
const Candidati = Loadable(lazy(() => import('pages/anagrafiche/candidati')));
const Candidato = Loadable(lazy(() => import('pages/anagrafiche/candidato')));
const Operatori = Loadable(lazy(() => import('pages/anagrafiche/operatori')));
const NuovoOperatore = Loadable(lazy(() => import('pages/anagrafiche/operatore/nuovo-operatore')));
import Operatore from 'pages/anagrafiche/operatore';
const AnagraficaOperatore = Loadable(lazy(() => import('pages/anagrafiche/operatore/anagrafica')));
const DocumentiCorsi = Loadable(lazy(() => import('pages/anagrafiche/operatore/documenti-corsi')));
const ContrattiPratiche = Loadable(lazy(() => import('pages/anagrafiche/operatore/contratti-pratiche')));
const Indisponibilita = Loadable(lazy(() => import('pages/anagrafiche/operatore/indisponibilita')));
const Clienti = Loadable(lazy(() => import('pages/anagrafiche/clienti')));
const NuovoCliente = Loadable(lazy(() => import('pages/anagrafiche/clienti/NuovoCliente')));
const Cliente = Loadable(lazy(() => import('pages/anagrafiche/cliente')));

// ==============================|| MAIN ROUTING ||============================== //
const AnagraficheRoutes = (user) =>
{
   return {
      path: '/',
      children: [
         {
            path: '/',
            element: (
               <AuthGuard>
                  <MainLayout />
               </AuthGuard>
            ),
            children: [
               {
                  path: 'anagrafiche',
                  children: [
                     {
                        path: 'candidati',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin','erp_candidati']}
                           >
                              <Candidati />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'candidati/:id/modifica',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin']}
                           >
                              <Candidato />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'operatori',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin','erp_operatori']}
                           >
                              <Operatori />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'operatori/aggiungi-nuovo',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin','erp_operatori_anagrafica']}
                           >
                              <NuovoOperatore />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'operatori/:id',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin','erp_operatori_anagrafica']}
                           >
                              <Operatore />
                           </RouteProtection>
                        ),
                        children: [
                           {
                              path: 'anagrafica',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin','erp_operatori_anagrafica']}
                                 >
                                    <AnagraficaOperatore />
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'documenti-corsi',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_operatori_documenti-corsi']}
                                 >
                                    <DocumentiCorsi />
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'contratti-pratiche',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_operatori_contratti-pratiche']}
                                 >
                                    <ContrattiPratiche />
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'indisponibilita',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_operatori_indisponibilita']}
                                 >
                                    <Indisponibilita />
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'altro',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'erp_admin', 'erp_operatori_altro']}
                                 >
                                    <p>Altro</p>
                                 </RouteProtection>
                              )
                           }
                        ]
                     },
                     {
                        path: 'clienti',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin']}
                           >
                              <Clienti />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'clienti/aggiungi-nuovo',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin']}
                           >
                              <NuovoCliente />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'clienti/:clienteId',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'erp_admin']}
                           >
                              <Cliente />
                           </RouteProtection>
                        )
                     }
                  ]
               }
            ]
         },
         {
            path: '*',
            element: (
               <p>Not found</p>
            )
         }
      ]
   }
}

export default AnagraficheRoutes;
