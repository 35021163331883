import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import
{
   Link as RouterLink
} from "react-router-dom";

//Mui
import
{
   Box,
   Breadcrumbs,
   Grid,
   Link,
   Stack,
   Typography
} from "@mui/material";


const PageHeader = (props) =>
{
   const {
      title,
      afterTitle,
      breadcrumbs,
      secondary,
      sx = {},
      ...others
   } = props;

   const Boxheader = styled(Box)(() => ({
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      alignItems: 'center',
      marginBottom: '1.5rem',
      ...sx
   }));

   return (
      <Boxheader
         component="header"
         {...others}
      >
         <Stack
            spacing={0.5}
         >
            <Grid container columnSpacing={2.5}>
               {
                  title && (
                     <Grid
                        item
                        xs='auto'
                     >
                        <Typography
                           variant="h2"
                        >
                           {title}
                        </Typography>
                     </Grid>
                  )
               }
               {
                  secondary && (
                     <Grid item>
                        {
                           secondary
                        }
                     </Grid>
                  )
               }
            </Grid>
            {
               afterTitle && (
                  <Grid
                     item
                     xs='auto'
                  >
                     <Typography
                        variant="h6"
                        sx={{
                           color: '#999'
                        }}
                     >
                        {afterTitle}
                     </Typography>
                  </Grid>
               )
            }
         </Stack>
         {
            breadcrumbs && (
               <Grid
                  item
                  sx={{
                     ml: 'auto',
                     display: {
                        xs: 'none',
                        md: 'block'
                     }
                  }}
               >
                  <Breadcrumbs
                  aria-label="breadcrumb"
                  separator="/"
                  sx={{
                     fontSize: '.75rem'
                  }}
                  >
                     <Link underline="hover" component={RouterLink} color="inherit" to="/">
                        Home
                     </Link>
                     {
                        breadcrumbs.map((item, index) =>
                        {
                           const last = index === breadcrumbs.length - 1;

                           return last || !item?.path ? (
                              <Typography
                                 key={`breadcrumb-item-${index}`}
                                 sx={{
                                    color: theme => last ? theme.palette.text.primary : theme.palette.text.secondary,
                                    fontSize: '.75rem'
                                 }}
                              >
                                 {item?.label}
                              </Typography>
                           ) : (
                              <Link underline="hover" component={RouterLink} color="inherit" to={item?.path} key={`breadcrumb-item-${index}`}>
                                 {item?.label}
                              </Link>
                           );
                        })
                     }
                  </Breadcrumbs>
               </Grid>
            )
         }
      </Boxheader>
   )
}

PageHeader.propTypes = {
   title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   afterTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   breadcrumbs: PropTypes.array,
   sx: PropTypes.object
};

export default PageHeader;