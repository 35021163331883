import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetch } from 'use-http';
import { useDispatch } from 'react-redux';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { TableProvider, useTableProvider } from 'contexts/TableProvider';
import { useBee } from 'contexts/Bee';
import { QuickViewCore } from 'contexts/QuickViewProvider';
import moment from 'moment';

//component
import PageHeader from 'components/PageHeader';
import MainCard from 'components/MainCard';
import ResourceTable from 'components/ResourceTable';
import SkeletonTable from 'components/SkeletonTable';

//project import
import { openSnackbar, closeSnackbar } from 'store/reducers/snackbar';

//MUI
import
{
   Alert,
   AlertTitle,
   Badge,
   Box,
   Button,
   CardContent,
   CircularProgress,
   FormControlLabel,
   Switch,
   InputLabel,
   Skeleton,
   Stack,
   TextField,
   Typography,
   TableFooter,
   TableRow,
   TableCell
} from '@mui/material'

import
{
   faStar as faStarSolid
} from '@fortawesome/pro-solid-svg-icons';

const ConsolidamentoAvanzatoMarkup = () =>
{
   //recupero id operatore dai parametri url
   const {
      idContratto,
      idServizio,
      idTurno
   } = useParams();

   const location = useLocation();

   console.log('LOCATION', location);

   //recupero i dati di paginazione e filtraggio dal context
   const {
      sortOrder,
      onSortChange,
      loading,
      error,
      isFirstRender,
      forceReload,
      data
   } = useTableProvider();

   const {
      formatSecondsToHours,
      convertHoursToSeconds,
   } = useBee();


   console.log('DATA', data);

   //inizializzo hook dispatch
   const dispatch = useDispatch()

   //inizializzo l'oggetto per la chiamata fetch
   const fetcher = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   const formPresenze = useForm({
      defaultValues: data
   });

   const {
      fields,
      update
   } = useFieldArray({
      keyName: 'uniqId',
      name: 'results',
      control: formPresenze.control
   });

   useEffect(() =>
   {
      formPresenze.reset(data);
      console.log('RESETTATO');
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data]);

   console.log('FIELDS', fields);

   //inizializzo gli status
   const [success, setSuccess] = useState(null);
   //inizializzo lo state delle colonne nascoste
   const [hiddenColumns] = useState([]);

   //imposto l'oggetto con i links pre la breadcrumbs
   const breadcrumbs = useMemo(() =>
   {
      return [
         {
            label: "Pianificazione",
         },
         {
            label: "Contratti",
            path: "/pianificazione/contratti"
         },
         {
            label: `Contratto #${idContratto}`,
            path: `/pianificazione/contratti/${idContratto}/dati-generali/`
         },
         {
            label: `Servizio #${idServizio}`,
            path: `/pianificazione/contratti/${idContratto}/${idServizio}/assegnazione-risorse/servizio`
         },
         {
            label: "Assegnazione risorse"
         }
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, loading]);

   //gestisco gli alerts
   useEffect(() =>
   {
      closeSnackbar();

      if (success)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: success?.message,
               variant: 'alert',
               alert: {
                  color: 'success'
               },
               close: false
            })
         );

         setSuccess(null);
      } else if (error)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: `Si è verificato un errore (${error?.message})`,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      return () =>
      {
         closeSnackbar();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [success, error]);

   useEffect(() =>
   {
      if (fetcher.error)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: `Si è verificato un errore (${fetcher.error?.message})`,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      return () =>
      {
         closeSnackbar();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fetcher.error]);

   //imposto l'ordine iniziale delle colonne della tabella
   const initialState = useMemo(
      () => ({
         columnOrder: [
            'posizione',
            'operatore',
            'presenza'
         ],
         hiddenColumns: hiddenColumns
      }),
      [hiddenColumns]
   );

   const columns = useMemo(
      () => [
         {
            Header: 'Area',
            accessor: 'posizione',
            // eslint-disable-next-line
            Cell: ({ value }) =>
            {
               return value?.descrizione || '-'
            }
         },
         {
            Header: 'Operatore',
            accessor: 'operatore',
            // eslint-disable-next-line
            Cell: ({ value }) =>
            {
               return `${value?.cognome} ${value?.nome}`
            }
         },
         {
            Header: 'Presenza',
            accessor: 'presenza',
            className: 'cell-center',
            // eslint-disable-next-line
            Cell: ({ row, value }) =>
            {
               const secondiAssegnati = parseInt(row?.original?.secondi_assegnati);
               const secondiLavorati = parseInt(row?.original?.secondi_lavorati);

               if (secondiLavorati === 0)
               {
                  return <Badge color="error" variant={'dot'} />
               } else if (secondiLavorati >= secondiAssegnati)
               {
                  return <Badge color="success" variant={'dot'} />
               } else if (secondiLavorati < secondiAssegnati)
               {
                  return <Badge color="warning" variant={'dot'} />
               }

            }
         },
         {
            Header: 'Assegnate',
            accessor: 'secondi_assegnati',
            className: 'cell-center',
            // eslint-disable-next-line
            Cell: ({ row, value }) =>
            {
               return (
                  <Box>
                     <Controller
                        control={formPresenze.control}
                        name={`results[${row.index}].secondi_assegnati`}
                        render={({ field, fieldState }) =>
                        {
                           return (
                              <TextField
                                 {...field}
                                 type="time"
                                 error={fieldState?.error ? true : undefined}
                                 helperText={fieldState?.error && fieldState?.error.message}
                                 size="small"
                                 name={`results[${row.index}].secondi_assegnati`}
                                 fullWidth
                                 readOnly
                                 disabled
                                 value={field.value ? formatSecondsToHours(field.value) : ''}
                                 onChange={(event) =>
                                 {
                                    const sec = convertHoursToSeconds(event.target.value);
                                    field.onChange(sec);
                                 }}
                                 FormHelperTextProps={{
                                    sx: HelperTextErrorStyle
                                 }}
                                 InputLabelProps={{
                                    shrink: true
                                 }}
                                 required
                                 sx={{
                                    '& .Mui-disabled': {
                                       color: (theme) =>
                                       {
                                          return theme.palette.text.primary;
                                       },
                                       WebkitTextFillColor: (theme) =>
                                       {
                                          return theme.palette.text.primary;
                                       }
                                    }
                                 }}
                              />
                           )

                        }}
                     />
                  </Box>
               );
            }
         },
         {
            Header: 'Lavorate',
            accessor: 'secondi_lavorati',
            className: 'cell-center',
            // eslint-disable-next-line
            Cell: ({ row, value }) =>
            {
               return (
                  <Box>
                     <Controller
                        control={formPresenze.control}
                        name={`results[${row.index}].secondi_lavorati`}
                        render={({ field, fieldState }) =>
                        {
                           return (
                              <TextField
                                 {...field}
                                 type="time"
                                 error={fieldState?.error ? true : undefined}
                                 helperText={fieldState?.error && fieldState?.error.message}
                                 size="small"
                                 name={`results[${row.index}].secondi_lavorati`}
                                 fullWidth
                                 readOnly
                                 disabled
                                 value={formatSecondsToHours(field.value)}
                                 onChange={(event) =>
                                 {
                                    const sec = convertHoursToSeconds(event.target.value);
                                    field.onChange(sec);
                                 }}
                                 FormHelperTextProps={{
                                    sx: HelperTextErrorStyle
                                 }}
                                 InputLabelProps={{
                                    shrink: true
                                 }}
                                 required
                                 sx={{
                                    '& .Mui-disabled': {
                                       color: (theme) =>
                                       {
                                          return theme.palette.text.primary;
                                       },
                                       WebkitTextFillColor: (theme) =>
                                       {
                                          return theme.palette.text.primary;
                                       }
                                    }
                                 }}
                              />
                           )

                        }}
                     />
                  </Box>
               );
            }
         },
         {
            Header: 'Pagate',
            accessor: 'secondi_pagati',
            className: 'cell-center',
            // eslint-disable-next-line
            Cell: ({ row, value }) =>
            {
               return (
                  <Box>
                     <Controller
                        control={formPresenze.control}
                        name={`results[${row.index}].secondi_pagati`}
                        render={({ field, fieldState }) =>
                        {
                           return (
                              <TextField
                                 {...field}
                                 type="time"
                                 error={fieldState?.error ? true : undefined}
                                 helperText={fieldState?.error && fieldState?.error.message}
                                 size="small"
                                 name={`results[${row.index}].secondi_pagati`}
                                 fullWidth
                                 value={field.value ? formatSecondsToHours(field.value) : ''}
                                 onChange={(event) =>
                                 {
                                    const sec = convertHoursToSeconds(event.target.value);
                                    field.onChange(sec);
                                 }}
                                 FormHelperTextProps={{
                                    sx: HelperTextErrorStyle
                                 }}
                                 InputLabelProps={{
                                    shrink: true
                                 }}
                                 required
                                 disabled={formPresenze.formState.isSubmitting}
                                 readOnly={formPresenze.formState.isSubmitting || formPresenze.formState.isValidating}
                              />
                           )

                        }}
                     />
                  </Box>
               );
            }
         },
         {
            Header: 'Valutazione',
            accessor: 'valutazione',
            className: 'cell-center',
            // eslint-disable-next-line
            Cell: ({ row, value }) =>
            {
               return (
                  <Box>
                     <Controller
                        control={formPresenze.control}
                        name={`results[${row.index}].valutazione`}
                        render={({ field }) =>
                        {
                           return (
                              <>
                                 <InputLabel
                                    htmlFor={`results_${row.index}_valutazione_1`}
                                    sx={{
                                       display: 'inline-flex',
                                       color: (theme) =>
                                       {
                                          return field.value >= 1 ? theme.palette.warning.main : '#cccccc'
                                       }
                                    }}
                                 >
                                    <input
                                       type="radio"
                                       name={`results[${row.index}].valutazione`}
                                       id={`results_${row.index}_valutazione_1`}
                                       {...field}
                                       value={1}
                                       checked={field.value === '1'}
                                       disabled={formPresenze.formState.isSubmitting}
                                       hidden
                                    />
                                    <FontAwesomeIcon icon={faStarSolid} size="lg" />
                                 </InputLabel>
                                 <InputLabel
                                    htmlFor={`results_${row.index}_valutazione_2`}
                                    sx={{
                                       display: 'inline-flex',
                                       color: (theme) =>
                                       {
                                          return field.value >= 2 ? theme.palette.warning.main : '#cccccc'
                                       }
                                    }}
                                 >
                                    <input
                                       type="radio"
                                       name={`results[${row.index}].valutazione`}
                                       id={`results_${row.index}_valutazione_2`}
                                       {...field}
                                       value={2}
                                       checked={field.value === '2'}
                                       disabled={formPresenze.formState.isSubmitting}
                                       hidden
                                    />
                                    <FontAwesomeIcon icon={faStarSolid} size="lg" />
                                 </InputLabel>
                                 <InputLabel
                                    htmlFor={`results_${row.index}_valutazione_3`}
                                    sx={{
                                       display: 'inline-flex',
                                       color: (theme) =>
                                       {
                                          return field.value >= 3 ? theme.palette.warning.main : '#cccccc'
                                       }
                                    }}
                                 >
                                    <input
                                       type="radio"
                                       name={`results[${row.index}].valutazione`}
                                       id={`results_${row.index}_valutazione_3`}
                                       {...field}
                                       value={3}
                                       checked={field.value === '3'}
                                       disabled={formPresenze.formState.isSubmitting}
                                       hidden
                                    />
                                    <FontAwesomeIcon icon={faStarSolid} size="lg" />
                                 </InputLabel>
                                 <InputLabel
                                    htmlFor={`results_${row.index}_valutazione_4`}
                                    sx={{
                                       display: 'inline-flex',
                                       color: (theme) =>
                                       {
                                          return field.value >= 4 ? theme.palette.warning.main : '#cccccc'
                                       }
                                    }}
                                 >
                                    <input
                                       type="radio"
                                       name={`results[${row.index}].valutazione`}
                                       id={`results_${row.index}_valutazione_4`}
                                       {...field}
                                       value={4}
                                       checked={field.value === '4'}
                                       disabled={formPresenze.formState.isSubmitting}
                                       hidden
                                    />
                                    <FontAwesomeIcon icon={faStarSolid} size="lg" />
                                 </InputLabel>
                                 <InputLabel
                                    htmlFor={`results_${row.index}_valutazione_5`}
                                    sx={{
                                       display: 'inline-flex',
                                       color: (theme) =>
                                       {
                                          return field.value === 5 ? theme.palette.warning.main : '#cccccc'
                                       }
                                    }}
                                 >
                                    <input
                                       type="radio"
                                       name={`results[${row.index}].valutazione`}
                                       id={`results_${row.index}_valutazione_5`}
                                       {...field}
                                       value={5}
                                       checked={field.value === '5'}
                                       disabled={formPresenze.formState.isSubmitting}
                                       hidden
                                    />
                                    <FontAwesomeIcon icon={faStarSolid} size="lg" />
                                 </InputLabel>
                              </>
                           )

                        }}
                     />
                  </Box>
               );
            }
         },
         {
            Header: 'Delta',
            accessor: 'delta',
            // eslint-disable-next-line
            Cell: ({ row, value }) =>
            {
               return (
                  <Box>
                     <Controller
                        control={formPresenze.control}
                        name={`results[${row.index}].delta`}
                        render={({ field, fieldState }) =>
                        {
                           return (
                              <TextField
                                 {...field}
                                 value={field.value || ''}
                                 type="number"
                                 error={fieldState?.error ? true : undefined}
                                 helperText={fieldState?.error && fieldState?.error.message}
                                 size="small"
                                 fullWidth
                                 name={`results[${row.index}].delta`}
                                 FormHelperTextProps={{
                                    sx: HelperTextErrorStyle
                                 }}
                                 InputLabelProps={{
                                    shrink: true
                                 }}
                                 sx={{
                                    '& .MuiInputBase-input': {
                                       width: 'auto'
                                    }
                                 }}
                                 required
                                 disabled={formPresenze.formState.isSubmitting}
                                 readOnly={formPresenze.formState.isSubmitting || formPresenze.formState.isValidating}
                              />
                           )

                        }}
                     />
                  </Box>
               );
            }
         },
         {
            Header: 'Note',
            accessor: 'note',
            // eslint-disable-next-line
            Cell: ({ row, value }) =>
            {
               return (
                  <Box>
                     <Controller
                        control={formPresenze.control}
                        name={`results[${row.index}].note`}
                        render={({ field, fieldState }) =>
                        {
                           return (
                              <TextField
                                 {...field}
                                 value={field.value || ''}
                                 error={fieldState?.error ? true : undefined}
                                 helperText={fieldState?.error && fieldState?.error.message}
                                 size="small"
                                 name={`results[${row.index}].note`}
                                 fullWidth
                                 FormHelperTextProps={{
                                    sx: HelperTextErrorStyle
                                 }}
                                 InputLabelProps={{
                                    shrink: true
                                 }}
                                 required
                                 disabled={formPresenze.formState.isSubmitting}
                                 readOnly={formPresenze.formState.isSubmitting || formPresenze.formState.isValidating}
                              />
                           )

                        }}
                     />
                  </Box>
               );
            }
         },
         {
            Header: 'Vero',
            accessor: 'vero',
            // eslint-disable-next-line
            Cell: ({ row, value }) =>
            {
               return (
                  <Box>
                     <Controller
                        control={formPresenze.control}
                        name={`results[${row.index}].vero`}
                        render={({ field }) =>
                        {
                           return (
                              <FormControlLabel
                                 control={
                                    <Switch
                                       {...field}
                                       size="small"
                                       value={field.value || false}
                                       checked={field.value || false}
                                       color={'primary'}
                                       disabled={formPresenze.formState.isSubmitting}
                                    />}
                              />
                           )

                        }}
                     />
                  </Box>
               );
            }
         }
      ],
      // eslint-disable-next-line
      [fields]
   );

   const HelperTextErrorStyle = useMemo(() =>
   {
      return {
         mx: 0
      }
   }, []);

   const onConsolida = async (formData) =>
   {

      const container = {
         data: formData?.results
      }

      // eslint-disable-next-line no-unused-vars
      const consolida = await fetcher.request.post(`/api/cons/servizi/${idServizio}/turni/${idTurno}/avanzato/`, container);

      if (fetcher.error)
      {
         return;
      }

      if (fetcher.response.ok)
      {
         forceReload();
      }
   }

   const assignedHoursToPaidHours = () =>
   {
      if (fields && fields.length > 0)
      {
         fields.forEach((field, index) =>
         {
            const secondi_assegnati = field.secondi_assegnati;
            update(index, { ...field, secondi_pagati: secondi_assegnati });
         });

      }
   }

   return (
      <>
         <PageHeader
            title="Presenze operatori"
            breadcrumbs={breadcrumbs}
            afterTitle={loading && (!fields || !formPresenze.formState.defaultValues) ? <Skeleton width={230} /> : (
               <Typography
                  variant="body"
               >
                  {
                     location?.state?.contratto?.cliente?.ragione_sociale && location?.state?.contratto?.titolo && (
                        <Typography
                           component="strong"
                           variant="body"
                        >
                           {location?.state?.contratto?.cliente?.ragione_sociale}: {location?.state?.contratto?.titolo}
                        </Typography>
                     )
                  }

                  {` - ${moment(moment(location?.state?.giorno?.giorno).toDate()).format('DD/MM/YYYY')}`}
                  {(location?.state?.turno?.inizio_f1 && location?.state?.turno?.fine_f1) && ` dalle ${location?.state?.turno?.inizio_f1} alle ${location?.state?.turno?.fine_f1}`}
                  {(location?.state?.turno?.inizio_f2 && location?.state?.turno?.fine_f2) && ` e dalle ${location?.state?.turno?.inizio_f2} alle ${location?.state?.turno?.fine_f2}`}

               </Typography>
            )}
         />
         <QuickViewCore>
            <Stack
               spacing={2}
            >
               <MainCard
                  content={false}
                  title={
                     <Stack
                        direction={'row'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                     >
                        Elenco presenze operatori {loading && !isFirstRender ? <CircularProgress color='inherit' size={16} sx={{ ml: 2 }} /> : ''}
                     </Stack>
                  }
                  secondary={
                     <Button
                        variant='contained'
                        onClick={() => assignedHoursToPaidHours()}
                        disabled={formPresenze.formState.isSubmitting}
                        size="small"
                     >Imposta default</Button>
                  }
               >
                  <CardContent
                     sx={{
                        px: 0,
                        '&:last-child': {
                           pb: 0,
                           pt: 0
                        }
                     }}
                  >
                     {
                        error && (
                           <Alert
                              severity="error"
                           >
                              <AlertTitle
                                 sx={{
                                    fontWeight: 700
                                 }}
                              >
                                 Errore server
                              </AlertTitle>
                              {`Si è verificato un problema durante il recupero dei dati (${error.message})`}
                           </Alert>
                        )
                     }
                     {
                        loading && isFirstRender && (
                           <SkeletonTable rows={5} columns={7} />
                        )
                     }
                     {
                        formPresenze.formState.defaultValues && (fields && formPresenze.formState.defaultValues) && (
                           <ResourceTable
                              columns={columns}
                              data={{
                                 results: fields
                              }}
                              initialState={initialState}
                              hiddenColumns={hiddenColumns}
                              //onRowClick={handleRowClick}
                              orderAsc={sortOrder}
                              onSortChange={onSortChange}
                              footer={
                                 <TableFooter>
                                    <TableRow>
                                       <TableCell colSpan={columns?.length}>
                                          <Stack
                                             direction="row"
                                             sx={{
                                                alignItems: 'center'
                                             }}
                                          >
                                             <Button
                                                variant="contained"
                                                onClick={formPresenze.handleSubmit(onConsolida)}
                                                disabled={formPresenze.formState.isSubmitting}
                                                startIcon={
                                                   formPresenze.formState.isSubmitting ? <CircularProgress size={16} sx={{ color: theme => theme.palette.common.white }} /> : ''
                                                }
                                                sx={{
                                                   ml: 'auto'
                                                }}
                                             >Consolida</Button>
                                          </Stack>
                                       </TableCell>
                                    </TableRow>
                                 </TableFooter>

                              }
                           />
                        )
                     }
                  </CardContent>
               </MainCard>
            </Stack>
         </QuickViewCore>
      </>
   )
}

ConsolidamentoAvanzatoMarkup.propTypes = {
   value: PropTypes.any,
   row: PropTypes.any,
};

const ConsolidamentoAvanzato = () =>
{

   //recupero id operatore dai parametri url
   const {
      idServizio,
      idTurno
   } = useParams();

   return (
      <TableProvider
         defaultRowsPerPage={9999}
         path={`/api/cons/servizi/${idServizio}/turni/${idTurno}/avanzato/`}>
         <QuickViewCore>
            <ConsolidamentoAvanzatoMarkup />
         </QuickViewCore>
      </TableProvider>
   )
}

export default ConsolidamentoAvanzato;