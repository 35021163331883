//MUI
import { TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import PropTypes from 'prop-types';
import moment from "moment"

const FormDateField = (props) =>
{
   const {
      control,
      name,
      dateFormat = 'YYYY-MM-DD',
      rules = {},
      defaultValue = "",
      InputLabelProps = {},
      onChange,
      ...others
   } = props

   return <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) =>
      {
         return (
            <TextField
               {...field}
               value={field.value ? moment(moment(field.value).toDate()).format(dateFormat) : undefined}
               error={fieldState?.error ? true : undefined}
               helperText={fieldState?.error && fieldState?.error.message}
               InputLabelProps={{
                  sx: {
                     '&.MuiInputLabel-root.Mui-required .MuiFormLabel-asterisk': {
                        color: 'red',
                        fontWeight: 700
                     }
                  },
                  ...InputLabelProps
               }}
               onChange={(event, data) =>
               {
                  onChange ? onChange(event, data, field) : field.onChange(event, data);
               }}
               
               {...others}
               type="date"
            />
         )
      }}
   />
}

FormDateField.propTypes = {
   control: PropTypes.object,
   name: PropTypes.string,
   dateFormat: PropTypes.string,
   rules: PropTypes.object,
   defaultValue: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   InputLabelProps: PropTypes.object,
   onChange: PropTypes.func
};

export default FormDateField;