/* eslint-disable no-unused-vars */
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// material-ui
import
{
   Button,
   Stack,
} from '@mui/material';

import
{
   faPlusLarge
} from '@fortawesome/pro-regular-svg-icons';

//Components
import PageHeader from 'components/PageHeader';
import Utenti from './Utenti';

const ListingUtenti = () =>
{
   //imposto l'oggetto con i links pre la breadcrumbs
   const breadcrumbs = useMemo(() =>
   {
      return [
         {
            label: "Crowd Management System",
         },
         {
            label: "Listing utenti"
         }
      ];
   }, []);

   const [addNewDrawer, setAddNewDrawer] = useState(false);

   return (
      <>
         <PageHeader
            title={'Listing Utenti'}
            secondary={
               <Button
                  variant='contained'
                  endIcon={<FontAwesomeIcon icon={faPlusLarge} />}
                  onClick={() => setAddNewDrawer({})}
               >Aggiungi nuovo</Button>
            }
            afterTitle={`Crowd Management System`}
            breadcrumbs={breadcrumbs}
         />
         <Stack
            spacing={2}
         >
            <Utenti addNewDrawer={addNewDrawer} />
         </Stack>
      </>
   )
}

export default ListingUtenti;