import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation, Outlet } from 'react-router';

//components
import PageHeader from 'components/PageHeader';

import
{
   Box,
   Tab,
   Tabs,
   Typography,
   useMediaQuery
} from '@mui/material'

import
{
   faCalendarXmark,
   faCalendarDays,
   faCalendarPen
} from '@fortawesome/pro-regular-svg-icons';

const ControlRoom = () =>
{

   //recupero l'oggetto location
   const { pathname } = useLocation();

   let selectedTab = 1;

   const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

   switch (true)
   {
      case /\/cms\/control-room\/eventi-passati/g.test(pathname):
         selectedTab = 0;
         break;
      case /\/cms\/control-room\/eventi-in-corso/g.test(pathname):
         selectedTab = 1;
         break;
      case /\/cms\/control-room\/eventi-futuri/g.test(pathname):
         selectedTab = 2;
         break;
      default:
         selectedTab = 1;
   }

   const breadcrumbs = useMemo(() =>
   {
      return [
         {
            label: "Crowd Management System",
         },
         {
            label: "Control Room"
         }
      ];
   }, []);

   const a11yProps = (index) =>
   {
      return {
         id: `simple-tab-${index}`,
         'aria-controls': `simple-tabpanel-${index}`,
      };
   }

   return (
      <>
         <PageHeader
            title={'Control Room'}
            afterTitle={`Crowd Management System`}
            breadcrumbs={breadcrumbs}
         />
         <Box
            sx={{
               width: '100%'
            }}
         >
            <Box
               sx={{
                  borderBottom: 1,
                  borderColor: '#ccc',
                  mb: 3
               }}>
               <Tabs
                  value={selectedTab}
                  aria-label="Tabs eventi"
               >
                  <Tab
                  label={lgDown ? <Typography variant="body2" component="span">PASSATI</Typography>: 'EVENTI PASSATI'}
                  icon={<FontAwesomeIcon size="lg" icon={faCalendarXmark} />}
                  iconPosition="start"
                  sx={{
                     'flexGrow': lgDown ? 1 : undefined
                  }}
                  {...a11yProps(0)}
                  component={RouterLink}
                  to={`/cms/control-room/eventi-passati/`}   
                  />
                  <Tab
                  label={lgDown ? <Typography variant="body2" component="span">IN CORSO</Typography>: 'EVENTI IN CORSO'}
                  icon={<FontAwesomeIcon size="lg" icon={faCalendarDays} />}
                  iconPosition="start"
                  sx={{
                     'flexGrow': lgDown ? 1 : undefined
                  }}
                  {...a11yProps(1)}
                  component={RouterLink}
                  to={`/cms/control-room/eventi-in-corso/`}   
                  />
                  <Tab
                  label={lgDown ? <Typography variant="body2" component="span">FUTURI</Typography>: 'EVENTI FUTURI'}
                  icon={<FontAwesomeIcon size="lg" icon={faCalendarPen} />}
                  iconPosition="start"
                  sx={{
                     'flexGrow': lgDown ? 1 : undefined
                  }}
                  {...a11yProps(2)}
                  component={RouterLink}
                  to={`/cms/control-room/eventi-futuri/`}
                  />
               </Tabs>
            </Box>
            <Box>
            <Outlet />
            </Box>
         </Box>
      </>
   )

}

export default ControlRoom;