/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteProtection from './RouteProtection';

//CMS
import ControlRoom from 'pages/cms/control-room';
const PastEvents = Loadable(lazy(() => import('pages/cms/control-room/PastEvents')));
const CurrentEvents = Loadable(lazy(() => import('pages/cms/control-room/CurrentEvents')));
const FutureEvents = Loadable(lazy(() => import('pages/cms/control-room/FutureEvents')));

import ListingEventi from 'pages/cms/eventi';
const ViewEvento = Loadable(lazy(() => import('pages/cms/control-room/evento')));
const EditEvento = Loadable(lazy(() => import('pages/cms/eventi/evento')));

import ListingUtenti from 'pages/cms/utenti';
// ==============================|| MAIN ROUTING ||============================== //

const CmsRoutes = (user) =>
{
   return {
      path: '/',
      children: [
         {
            path: '/',
            element: (
               <AuthGuard>
                  <MainLayout />
               </AuthGuard>
            ),
            children: [
               {
                  path: 'cms',
                  children: [
                     {
                        path: 'control-room',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'cms_admin', 'cms_view_control_room']}
                           >
                              <ControlRoom />
                           </RouteProtection>
                        ),
                        children: [
                           {
                              path: 'eventi-passati',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'cms_admin', 'cms_view_control_room']}
                                 >
                                    <PastEvents />
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'eventi-in-corso',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'cms_admin', 'cms_view_control_room']}
                                 >
                                    <CurrentEvents />
                                 </RouteProtection>
                              )
                           },
                           {
                              path: 'eventi-futuri',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'cms_admin', 'cms_view_control_room']}
                                 >
                                    <FutureEvents />
                                 </RouteProtection>
                              )
                           }
                        ]
                     },
                     {
                        path: 'control-room/evento',
                        children: [
                           {
                              path: ':idEvento',
                              element: (
                                 <RouteProtection
                                    user={user}
                                    capabilities={['superadmin', 'admin', 'cms_admin', 'cms_view_control_room']}
                                 >
                                    <ViewEvento />
                                 </RouteProtection>
                              )
                           }
                        ]
                     },
                     {
                        path: 'eventi',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'cms_admin']}
                           >
                              <ListingEventi />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'eventi/:idEvento',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'cms_admin']}
                           >
                              <EditEvento />
                           </RouteProtection>
                        )
                     },
                     {
                        path: 'utenti',
                        element: (
                           <RouteProtection
                              capabilities={['superadmin', 'admin', 'cms_admin']}
                           >
                              <ListingUtenti />
                           </RouteProtection>
                        )
                     }
                  ]
               }
            ]
         },
         {
            path: '*',
            element: (
               <p>Not found</p>
            )
         }
      ]
   }
}

export default CmsRoutes;
