/* eslint-disable no-unused-vars */
import { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation, Outlet } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetch } from 'use-http';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
//import { useForm, FormProvider } from 'react-hook-form';
import { TableProvider, useTableProvider } from 'contexts/TableProvider';
import { QuickViewCore } from 'contexts/QuickViewProvider';
import moment from 'moment';

//component
import PageHeader from 'components/PageHeader';

//project import
import { openSnackbar, closeSnackbar } from 'store/reducers/snackbar';

//MUI
import
{
   Box,
   Divider,
   Skeleton,
   Tab,
   Tabs,
   Typography
} from '@mui/material'

import { LoadingButton } from '@mui/lab';

//FA Icons
import
{
   faTrash,
   faFloppyDisk,
   faCalendar,
   faUserGroup,
   faCoins,
   faBriefcase,
   faPaperclip
} from '@fortawesome/pro-regular-svg-icons';

const AssegnazioneRisorseMarkup = () =>
{
   //recupero id operatore dai parametri url
   const {
      idContratto,
      idServizio
   } = useParams();

   //recupero i dati di paginazione e filtraggio dal context
   const {
      page,
      rowsPerPage,
      sortOrder,
      totalCount,
      onPageChange,
      onRowsPerPageChange,
      onSortChange,
      exportData,
      loading,
      error,
      data
   } = useTableProvider();

   console.log('DATA', data);

   //recupero l'oggetto location
   const { pathname } = useLocation();

   console.log('pathname', pathname);

   //inizializzo hook dispatch
   const dispatch = useDispatch()

   const {
      request,
   } = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   //inizializzo gli status
   const [tab, setTab] = useState(0);
   const [success, setSuccess] = useState(null);

   useEffect(() => {
      switch (true)
   {
      case /\/pianificazione\/contratti\/([0-9]*)\/([0-9]*)\/assegnazione-risorse\/servizio/g.test(pathname):
         setTab(0);
         break;
      case /\/pianificazione\/contratti\/([0-9]*)\/([0-9]*)\/assegnazione-risorse\/operatori-assegnati/g.test(pathname):
         setTab(1);
         break;
      case /\/pianificazione\/contratti\/([0-9]*)\/([0-9]*)\/assegnazione-risorse\/costi/g.test(pathname):
         setTab(2);
         break;
      case /\/pianificazione\/contratti\/([0-9]*)\/([0-9]*)\/assegnazione-risorse\/dotazioni/g.test(pathname):
         setTab(3);
         break;
      case /\/pianificazione\/contratti\/([0-9]*)\/([0-9]*)\/assegnazione-risorse\/note-ed-allegati/g.test(pathname):
         setTab(4);
         break;
      default:
         setTab(0);
   }
   }, [pathname]);

   //gestione tab attivo
   const handleTab = (event, newValue) =>
   {
      setTab(newValue);
   };

   //imposto l'oggetto con i links pre la breadcrumbs
   const breadcrumbs = useMemo(() =>
   {
      return [
         {
            label: "Pianificazione",
         },
         {
            label: "Contratti",
            path: "/pianificazione/contratti"
         },
         {
            label: `Contratto #${idContratto}`,
            path: `/pianificazione/contratti/${idContratto}/servizi/`
         },
         {
            label: "Assegnazione risorse"
         }
      ];
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, loading]);

   //gestisco gli alerts
   useEffect(() =>
   {
      closeSnackbar();

      if (success)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: "Modifiche salvate correttamente",
               variant: 'alert',
               alert: {
                  color: 'success'
               },
               close: false
            })
         );

         setSuccess(null);
      } else if (error)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: `Si è verificato un errore (${error?.message})`,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      return () =>
      {
         closeSnackbar();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [success, error]);

   const StyledTab = styled(Tab)(() => ({
      lineHeight: 1
   }));

   console.log('DATA CLIENTE', data);

   return (
      <>
         <PageHeader
            title={
               loading ? <Skeleton width={150} /> : data?.contratto?.titolo
            }
            breadcrumbs={breadcrumbs}
            afterTitle={loading ? <Skeleton width={230} /> : (
               <Typography
                  variant="body"
               >
                  <Typography
                     component="strong"
                     variant="body"
                  >
                     {data?.contratto?.cliente?.ragione_sociale}
                  </Typography>

                  {` - dal ${moment(moment(data?.dal).toDate()).format('DD/MM/YYYY')} al ${moment(moment(data?.al).toDate()).format('DD/MM/YYYY')} - `}

                  <Typography
                     component="strong"
                     variant="body"
                  >
                     Gestione risorse
                  </Typography>

               </Typography>
            )}
            sx={{
               marginBottom: '.25rem'
            }}
         />
         <Box
            sx={{
               mb: 1.5
            }}
         >
            <Tabs
               value={tab}
               onChange={handleTab}
               variant="scrollable"
               scrollButtons="auto"
               allowScrollButtonsMobile
               aria-label="Contratto">
               <StyledTab
                  component={RouterLink}
                  label="Servizio"
                  to={`/pianificazione/contratti/${idContratto}/${idServizio}/assegnazione-risorse/servizio`}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  iconPosition="start"
               />
               <StyledTab
                  component={RouterLink}
                  label="Operatori assegnati"
                  to={`/pianificazione/contratti/${idContratto}/${idServizio}/assegnazione-risorse/operatori-assegnati`}
                  icon={<FontAwesomeIcon icon={faUserGroup} />}
                  iconPosition="start"
               />
               <StyledTab
                  component={RouterLink}
                  label="Costi"
                  to={`/pianificazione/contratti/${idContratto}/${idServizio}/assegnazione-risorse/costi`}
                  icon={<FontAwesomeIcon icon={faCoins} />}
                  iconPosition="start"
               />
               <StyledTab
                  component={RouterLink}
                  label="Dotazioni"
                  to={`/pianificazione/contratti/${idContratto}/${idServizio}/assegnazione-risorse/dotazioni`}
                  icon={<FontAwesomeIcon icon={faBriefcase} />}
                  iconPosition="start"
               />
               <StyledTab
                  component={RouterLink}
                  label="Note ed Allegati"
                  to={`/pianificazione/contratti/${idContratto}/${idServizio}/assegnazione-risorse/note-ed-allegati`}
                  icon={<FontAwesomeIcon icon={faPaperclip} />}
                  iconPosition="start"
               />
            </Tabs>
            <Divider
               sx={{
                  borderColor: '#eee'
               }}
            />
         </Box>
         <Box>
            <Outlet />
         </Box>
         {/* <Box sx={{
            mt: 3,
            position: 'sticky',
            bottom: 0,
            zIndex: 100,
            backgroundColor: '#fafafb',
            py: 2,
            display: tab === 0 ? 'none' : 'block',
         }} textAlign="right">
            <LoadingButton
               variant="contained"
               color="error"
               startIcon={<FontAwesomeIcon icon={faTrash} />} sx={{ mr: 2 }}
               loadingPosition="start"
            >
               Elimina
            </LoadingButton>
            <LoadingButton
               color="primary"
               variant="contained"
               startIcon={<FontAwesomeIcon icon={faFloppyDisk} />} size="large"
               loadingPosition="start"
            >
               Salva le modifiche
            </LoadingButton>
         </Box> */}
      </>
   )
}

const AssegnazioneRisorse = () =>
{

   //recupero id operatore dai parametri url
   const {
      idContratto,
      idServizio
   } = useParams();

   return (
      <TableProvider path={`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/`}>
         <QuickViewCore>
            <AssegnazioneRisorseMarkup />
         </QuickViewCore>
      </TableProvider>
   )
}

export default AssegnazioneRisorse;