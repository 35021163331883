import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// icons
import {
   faIdCard,
   faGauge,
   faObjectsColumn
} from '@fortawesome/pro-solid-svg-icons';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const mainMenu = {
   id: 'group-main-menu',
   title: <FormattedMessage id="main-menu.title" defaultMessage="Menu principale" />,
   type: 'group',
   permissions: ['superadmin','admin','erp_admin','erp_dashboard'],
   children: [
      {
         id: 'dashboard',
         title: <FormattedMessage id="dashboard.title" defaultMessage="Dashboard" />,
         type: 'item',
         url: '/',
         permissions: ['superadmin','admin','erp_admin','erp_dashboard'],
         icon: () => <FontAwesomeIcon icon={faGauge} size="lg"/>,
      },
      {
         id: 'pianificazione',
         title: 'Pianificazione',
         type: 'collapse',
         permissions: ['superadmin','admin','erp_admin'],
         icon: () => <FontAwesomeIcon icon={faObjectsColumn} size="lg"/>,
         children: [
            {
               id: 'contratti',
               title: 'Contratti',
               type: 'item',
               permissions: ['superadmin','admin','erp_admin'],
               url: '/pianificazione/contratti'
            },
            {
               id: 'intermittenti',
               title: 'Intermittenti',
               type: 'item',
               permissions: ['superadmin','admin','erp_admin'],
               url: '/pianificazione/intermittenti'
            }
         ]
      },
      {
         id: 'anagrafiche',
         title: <FormattedMessage id="anagrafiche.title" defaultMessage="Anagrafiche" />,
         type: 'collapse',
         permissions: ['superadmin','admin','erp_admin','erp_operatori','erp_candidati'],
         icon: () => <FontAwesomeIcon icon={faIdCard} size="lg"/>,
         children: [
            {
               id: 'operatori',
               title: <FormattedMessage id="operatori.title" defaultMessage="Operatori" />,
               type: 'item',
               permissions: ['superadmin','admin','erp_admin','erp_operatori'],
               url: '/anagrafiche/operatori'
            },
            {
               id: 'clienti',
               title: <FormattedMessage id="clienti.title" defaultMessage="Clienti" />,
               type: 'item',
               permissions: ['superadmin','admin','erp_admin'],
               url: '/anagrafiche/clienti'
            },
            {
               id: 'candidati',
               title: <FormattedMessage id="candidati.title" defaultMessage="Candidati" />,
               type: 'item',
               permissions: ['superadmin','admin','erp_admin','erp_candidati'],
               url: '/anagrafiche/candidati'
            }
         ]
      }
   ]
};

export default mainMenu;
