import { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation, Outlet } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetch } from 'use-http';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useBee } from 'contexts/Bee';

//component
import PageHeader from 'components/PageHeader';

//project import
import { openSnackbar } from 'store/reducers/snackbar';

//MUI
import
{
   Box,
   Divider,
   Skeleton,
   Tab,
   Tabs
} from '@mui/material'

import { LoadingButton } from '@mui/lab';

//FA Icons
import
{
   faUser,
   faFileLines,
   faFileSignature,
   faCalendarXmark,
   faFolderOpen,
   faTrash,
   faFloppyDisk,
} from '@fortawesome/pro-regular-svg-icons';

const Operatore = () =>
{
   //recupero id operatore dai parametri url
   const { id: operatoreId } = useParams();

   const navigate = useNavigate();

   //inizializzo hook dispatch
   const dispatch = useDispatch();

   //recupero l'oggetto location
   const { pathname } = useLocation();

   const {
      prepareData,
      stringifyData
   } = useBee();

   const { request, response, error, loading } = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   let selectedTab = 0;

   switch (true)
   {
      case /\/anagrafiche\/operatori\/([0-9]*)\/anagrafica/g.test(pathname):
         selectedTab = 0;
         break;
      case /\/anagrafiche\/operatori\/([0-9]*)\/documenti-corsi/g.test(pathname):
         selectedTab = 1;
         break;
      case /\/anagrafiche\/operatori\/([0-9]*)\/contratti-pratiche/g.test(pathname):
         selectedTab = 2;
         break;
      case /\/anagrafiche\/operatori\/([0-9]*)\/indisponibilita/g.test(pathname):
         selectedTab = 3;
         break;
      case /\/anagrafiche\/operatori\/([0-9]*)\/altro/g.test(pathname):
         selectedTab = 4;
         break;
      default:
         selectedTab = 0;
   }

   //inizializzo state dei tabs
   const [tab, setTab] = useState(selectedTab);
   const [updating, setUpdating] = useState(false);
   const [deleting, setDeleting] = useState(false);
   const [success, setSuccess] = useState(null);
   const [clienti, setClienti] = useState([]);
   

   //gestione tab attivo
   const handleTab = (event, newValue) =>
   {
      setTab(newValue);
   };

   const StyledTab = styled(Tab)(() => ({
      lineHeight: 1
   }));

   const defaultFields = useMemo(() =>
   {
      return {
         altezza: '',
         amm_note: '',
         ammortizzatori: true,
         annotazioni: '',
         applicazione: true,
         area_agenzia: '',
         area_sede: {},
         attivo: true,
         black_list: true,
         cap: '',
         cellulare: '',
         cessato: false,
         citta: '',
         cittadinanza: '',
         cod_pref: '',
         codice_fiscale: '',
         codice: '',
         cognome: '',
         compensi: [],
         contratti: [],
         corsi: [],
         cp_rilasciato_da: '',
         cp_rilasciato_il: '',
         cp_scadenza: '',
         curriculum: '',
         d_cap: '',
         d_citta: '',
         d_indirizzo: '',
         d_numero: '',
         d_prov: '',
         data_disattivazione: '',
         data_iban: '',
         data_iscrizione: '',
         data_nascita: '',
         documenti: [],
         email: '',
         foto_busto: '',
         foto_intera: '',
         foto_primo_piano: '',
         foto_profilo: '',
         iban: '',
         id: 0,
         indirizzo: '',
         luogo_nascita: '',
         nome: '',
         note: '',
         numero: '',
         pec: '',
         peso: '',
         posizioni_operatore: [],
         pratiche: [],
         prov: '',
         titolo_studio: '',
         referente: '',
         sesso: "M",
         sms: true,
         telefono: ''
      }
   }, []);

   useEffect(() => 
   {
      if (success !== null)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: success?.message,
               variant: 'alert',
               alert: {
                  color: 'success'
               },
               close: false
            })
         );
      }

      return () =>
      {
         setSuccess(null);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [success]);

   useEffect(() => 
   {
      if (error)
      {

         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: response.data?.message || error?.message,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [error]);

   useEffect(() =>
   {

      const fetchUtils = async () =>
      {

         const [c] = await Promise.all([
            request.get('/api/anagrafiche/clienti/?page_size=99999')
         ]);

         if (response.ok)
         {
            setClienti(c.results);
         }

      }

      fetchUtils();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   //inizializzo hook form
   const methods = useForm({
      defaultValues: async () =>
      {
         let results = await request.get(`/api/anagrafiche/operatori/${operatoreId}/`);

         if( response.ok )
         {
            results = stringifyData(results, ['referente', 'area_agenzia', 'titolo_studio']);
            return results;
         }

         return defaultFields;
      }
   });

   //imposto l'oggetto con i links pre la breadcrumbs
   const breadcrumbs = useMemo(() =>
   {
      return [
         {
            label: "Anagrafiche",
         },
         {
            path: "/anagrafiche/operatori/",
            label: "Operatori"
         },
         {
            label: methods.formState?.isLoading ? <Skeleton width={100} /> : `Operatore ${methods.getValues('codice')}`
         }
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [methods.formState]);

   const onSubmit = async (formData) =>
   {
      if (!updating)
      {
         setUpdating(true);

         formData = prepareData(formData, ['referente', 'area_agenzia', 'titolo_studio']);

         const container = {
            data: formData
         }

         console.log('container', container);

         const update = await request.put(`/api/anagrafiche/operatori/${operatoreId}/`, container);

         if (response.ok)
         {
            //const output = prepareData(update);
            methods.reset({ ...stringifyData(update, ['referente', 'area_agenzia', 'titolo_studio']) });

            setSuccess({
               message: 'Modifiche salvate correttamente: Aggiorno i dati...'
            });
         }

         setUpdating(false);
      }
   }

   const handleDelete = async (id) =>
   {
      if (!deleting)
      {
         setDeleting(true);

         if (window.confirm('Sei sicuro di voler eliminare questo operatore? Questa operazione non può essere annullata.'))
         {
            const deleted = await request.delete(`/api/anagrafiche/operatori/${id}/`);

            if (response.ok && deleted.result === 'OK')
            {
               dispatch(
                  openSnackbar({
                     anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                     },
                     open: true,
                     message: 'Operatore eliminato',
                     variant: 'alert',
                     alert: {
                        color: 'success'
                     },
                     close: false
                  })
               );

               navigate('/anagrafiche/operatori');
            }
         }

         setDeleting(false);
      }
   }

   return (
      <>
         <PageHeader
            title={methods.formState?.isLoading ? <Skeleton width={200} /> : `${methods.getValues('nome')} ${methods.getValues('cognome')}`}
            afterTitle={methods.formState?.isLoading ? <Skeleton width={150} /> : `Operatore ${methods.getValues('codice')}`}
            breadcrumbs={breadcrumbs}
         />
         <FormProvider {...methods} >
            <Box
               sx={{
                  mb: 3
               }}
            >
               <Tabs
                  value={tab}
                  onChange={handleTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="Anagrafica operatore">
                  <StyledTab
                     component={RouterLink}
                     label="Anagrafica"
                     to={`/anagrafiche/operatori/${operatoreId}/anagrafica`}
                     icon={<FontAwesomeIcon icon={faUser} />}
                     iconPosition="start"
                  />
                  <StyledTab
                     component={RouterLink}
                     label="Documenti/Corsi"
                     to={`/anagrafiche/operatori/${operatoreId}/documenti-corsi`}
                     icon={<FontAwesomeIcon icon={faFileLines} />}
                     iconPosition="start"
                  />
                  <StyledTab
                     component={RouterLink}
                     label="Contratti/Pratiche"
                     to={`/anagrafiche/operatori/${operatoreId}/contratti-pratiche`}
                     icon={<FontAwesomeIcon icon={faFileSignature} />}
                     iconPosition="start"
                  />
                  <StyledTab
                     component={RouterLink}
                     label="Indisponibilità"
                     to={`/anagrafiche/operatori/${operatoreId}/indisponibilita`}
                     icon={<FontAwesomeIcon icon={faCalendarXmark} />}
                     iconPosition="start"
                  />
                  <StyledTab
                     component={RouterLink}
                     label="Altro"
                     to={`/anagrafiche/operatori/${operatoreId}/altro`}
                     icon={<FontAwesomeIcon icon={faFolderOpen} />}
                     iconPosition="start"
                  />
               </Tabs>
               <Divider
                  sx={{
                     borderColor: '#eee'
                  }}
               />
            </Box>
            <Box>
               <Outlet context={{
                  loading,
                  clienti
               }} />
            </Box>
            <Box sx={{
               mt: 3,
               position: 'sticky',
               bottom: 0,
               zIndex: 100,
               backgroundColor: '#fafafb',
               py: 2
            }} textAlign="right">
               <LoadingButton
                  variant="contained"
                  color="error"
                  startIcon={<FontAwesomeIcon icon={faTrash} />} sx={{ mr: 2 }}
                  loading={deleting}
                  loadingPosition="start"
                  onClick={() => handleDelete(operatoreId)}
               >
                  Elimina
               </LoadingButton>
               <LoadingButton
                  color="success"
                  variant="contained"
                  startIcon={<FontAwesomeIcon icon={faFloppyDisk} />} size="large"
                  loading={updating}
                  loadingPosition="start"
                  onClick={methods.handleSubmit(onSubmit)}
               >
                  Salva le modifiche
               </LoadingButton>
            </Box>
         </FormProvider>
      </>
   )
}

export default Operatore;