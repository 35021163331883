import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

//project import
import { useColumnOrder, useTable, useGroupBy, useExpanded } from 'react-table';
import update from 'immutability-helper';
import
{
   DraggableHeader,
   DragPreview
} from 'components/third-party/ReactTable';

import
{
   faArrowRight,
   faArrowDown
} from '@fortawesome/pro-light-svg-icons';

//MUI
import
{
   Alert,
   Stack,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   TableSortLabel,
   Typography
} from '@mui/material';



const ReactTable = (props) =>
{

   const theme = useTheme();

   const {
      columns,
      data,
      initialState,
      hiddenColumns,
      getTrProps,
      onRowClick,
      onCellClick,
      header = false,
      footer,
      orderAsc,
      groupByField = null,
      onSortChange,
      onGroupByChange,
      sx
   } = props

   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      // eslint-disable-next-line no-unused-vars
      setGroupBy,
      prepareRow,
      // @ts-ignore
      setColumnOrder,
      setHiddenColumns,
      // eslint-disable-next-line no-unused-vars
      state: { groupBy },
      // @ts-ignore
      state: { columnOrder }
   } = useTable(
      {
         columns,
         data,
         getTrProps,
         initialState
      },
      useColumnOrder,
      useGroupBy,
      useExpanded
   );

   useEffect(() =>
   {
      setGroupBy(groupByField);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [groupByField]);

   useEffect(() =>
   {
      setHiddenColumns(hiddenColumns);
   }, [hiddenColumns, setHiddenColumns]);

   const reorder = (item, newIndex) =>
   {
      const { index: currentIndex } = item;

      const dragRecord = columnOrder[currentIndex];

      setColumnOrder(
         update(columnOrder, {
            $splice: [
               [currentIndex, 1],
               [newIndex, 0, dragRecord]
            ]
         })
      );
   }

   return (
      <>
         <Table
            size="small"
            sx={{
               '& td': {
                  fontSize: '.75rem'
               },
               '& thead th': {
                  cursor: 'move'
               },
               ...sx
            }}
            {...getTableProps()}
         >
            {
               header && (
                  <TableHead>
                     {headerGroups.map((headerGroup, i) => (
                        <TableRow
                           key={i}
                           {...headerGroup.getHeaderGroupProps()}
                        >
                           {headerGroup.headers.map((column, index) =>
                           {
                              return (
                                 <TableCell
                                    key={index}
                                    sx={{
                                       whiteSpace: 'nowrap',
                                       ...column.sx
                                    }}
                                    {...column.getHeaderProps([{ className: column.className }])}
                                 >
                                    <DraggableHeader reorder={reorder} key={column.id} column={column} index={index}>
                                       {
                                          !column.hiddenLabel && (
                                             <Stack
                                                direction="row"
                                                alignItems={'center'}
                                                className={column.className}
                                             >
                                                <Typography
                                                   variant="body2"
                                                   sx={{
                                                      lineHeight: 1,
                                                      fontWeight: 700,
                                                      textDecoration: column.aggregate ? 'underline' : 'none',
                                                      textUnderlineOffset: 3,
                                                      textDecorationThickness: 2,
                                                      textDecorationColor: column.isGrouped ? 'primary.main' : '#cccccc',
                                                      cursor: 'pointer'
                                                   }}
                                                   // {...column.getGroupByToggleProps()}
                                                   onClick={() =>
                                                   {
                                                      column.aggregate && onGroupByChange(groupBy.includes(column.id) ? [] : [column.id]);
                                                   }}
                                                >
                                                   {column.render('Header')}
                                                </Typography>
                                                {
                                                   column.sortable && (
                                                      <TableSortLabel
                                                         direction={orderAsc ? 'asc' : 'desc'}
                                                         onClick={() => onSortChange(column.id, orderAsc)}
                                                         active
                                                      >
                                                      </TableSortLabel>
                                                   )
                                                }
                                             </Stack>
                                          )
                                       }
                                    </DraggableHeader>
                                 </TableCell>
                              )
                           })}
                        </TableRow>
                     ))}
                  </TableHead>
               )
            }
            <TableBody {...getTableBodyProps()}>
               {
                  !rows.length && (
                     <TableRow>
                        <TableCell
                           colSpan={columns.length}
                           align="center"
                           sx={{
                              '&.MuiTableCell-root:first-of-type': {
                                 p: 0
                              }
                           }}
                        >
                           <Alert severity="info">Nessun risultato trovato</Alert>
                        </TableCell>
                     </TableRow>
                  )
               }
               {rows.map((row, i) =>
               {
                  prepareRow(row);
                  return (
                     <TableRow
                        sx={{
                           '&:last-child td, &:last-child th': {
                              border: 0
                           },
                           height: '2.5rem',
                           cursor: 'pointer',
                           '& td': {
                              px: '.75rem'
                           }
                        }}
                        onClick={(event) => onRowClick(event, row?.original)}
                        {...row.getRowProps(getTrProps(row))}
                        key={i}
                     >
                        {row.cells.map((cell, i) => (
                           <TableCell
                              key={i}
                              onClick={(event) => onCellClick(event, row, cell.column)}
                              {...cell.getCellProps([{
                                 className: cell.column.className, sx: {
                                    backgroundColor: cell.isGrouped
                                       ? theme.palette.success.lighter
                                       : cell.isAggregated
                                          ? theme.palette.warning.lighter
                                          : cell.isPlaceholder
                                             ? theme.palette.error.lighter
                                             : 'white',
                                    ...cell.column.sx
                                 }
                              }])}

                           >
                              {cell.isGrouped ? (
                                 <>
                                    <span {...row.getToggleRowExpandedProps()}>
                                       <FontAwesomeIcon icon={row.isExpanded ? faArrowDown : faArrowRight} />
                                    </span>&nbsp;&nbsp;
                                    {cell.render('Cell')} ({row.subRows.length})
                                 </>
                              ) : cell.isAggregated ? (
                                 cell.render('Aggregated')
                              ) : cell.isPlaceholder ? null : (
                                 cell.render('Cell')
                              )}
                           </TableCell>
                        ))}
                     </TableRow>
                  );
               })}
            </TableBody>
            {
               footer && (footer)
            }
         </Table>
      </>
   )

}

ReactTable.propTypes = {
   columns: PropTypes.array,
   data: PropTypes.array,
   getTrProps: PropTypes.func,
   initialState: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
   hiddenColumns: PropTypes.array,
   onSort: PropTypes.func,
   onRowClick: PropTypes.func,
   onCellClick: PropTypes.func,
   header: PropTypes.bool,
   footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   orderAsc: PropTypes.bool,
   groupByField: PropTypes.array,
   onSortChange: PropTypes.func,
   onGroupByChange: PropTypes.func,
   sx: PropTypes.object
};

const ResourceTable = (props) =>
{

   const {
      data,
      columns,
      getTrProps = () => { },
      initialState = {},
      hiddenColumns = [],
      onSort = () => { },
      onRowClick = () => { },
      onCellClick = () => { },
      header = true,
      footer,
      orderAsc = true,
      groupByField = [],
      onSortChange = () => { },
      onGroupByChange = () => { },
      sx,
   } = props;


   return (
      <TableContainer>
         {
            data?.results && (
               <>
                  <ReactTable
                     columns={columns}
                     data={data?.results}
                     getTrProps={getTrProps}
                     initialState={initialState}
                     hiddenColumns={hiddenColumns}
                     onSort={onSort}
                     groupByField={groupByField}
                     onRowClick={onRowClick}
                     onCellClick={onCellClick}
                     header={header}
                     footer={footer}
                     orderAsc={orderAsc}
                     onSortChange={onSortChange}
                     onGroupByChange={onGroupByChange}
                     sx={sx}
                  />
                  <DragPreview />
               </>
            )
         }
      </TableContainer>
   )
}

ResourceTable.propTypes = {
   columns: PropTypes.array,
   data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
   getTrProps: PropTypes.func,
   initialState: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
   hiddenColumns: PropTypes.array,
   onSort: PropTypes.func,
   onRowClick: PropTypes.func,
   onCellClick: PropTypes.func,
   header: PropTypes.bool,
   footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   paginationProps: PropTypes.object,
   orderAsc: PropTypes.bool,
   groupByField: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
   onSortChange: PropTypes.func,
   onGroupByChange: PropTypes.func,
   sx: PropTypes.object
};

export default ResourceTable;