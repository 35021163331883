import mainMenu from './main-menu';
//import tabelle from './tabelle';
import report from './report';
import consoleMenu from './console-menu';
import cmsMenu from './cms-menu';
//import other from './other';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
   items: [mainMenu, report, consoleMenu, cmsMenu]
};

export default menuItems;
