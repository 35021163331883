// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import HttpProvider from 'contexts/HttpProvider';


// auth provider
//import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
//import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
//import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
import { BeeAuthProvider as AuthProvider } from 'contexts/BeeAuthContext';
import { NotificheCore } from 'contexts/Notifiche';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
   <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
         <ScrollTop>
            <HttpProvider>
               <AuthProvider>
                  <NotificheCore>
                     <Routes />
                     <Snackbar />
                  </NotificheCore>
               </AuthProvider>
            </HttpProvider>
         </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
   </ThemeCustomization>
);

export default App;
