//import { useMemo } from 'react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
//import useConfig from 'hooks/useConfig';
//import Search from './Search';
//import Message from './Message';
//import Fullscreen from './Fullscreen';
//import Collaborator from './Collaborator';
//import Collaborators from './Collaborators';
//import Contract from './Contract';
//import Contracts from './Contracts';
//import Customer from './Customer';
import Profile from './Profile';
//import Localization from './Localization';
import Notification from './Notification';
import MobileSection from './MobileSection';
//import MegaMenuSection from './MegaMenuSection';
import QuickActions from './QuickActions';
import PrivateElement from 'components/PrivateElement';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  //const { i18n } = useConfig();

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
//   const localization = useMemo(() => <Localization />, [i18n]);

//   const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <Box
      sx={{
        display: 'flex',
        ml: 'auto'
      }}
    >
      {/* {!matchesXs && <Search />} */}
      {/* {!matchesXs && megaMenu} */}
      {/* {!matchesXs && localization} */}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      <PrivateElement capabilities={['superadmin','admin','erp_admin']}>
         <Notification />
      </PrivateElement>
      <QuickActions/>
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </Box>
  );
};

export default HeaderContent;
