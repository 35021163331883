import PropTypes from 'prop-types';
import { Provider } from "use-http";

const HttpProvider = (props) =>
{

   const {
      children
   } = props

   const options = {
      interceptors: {
         request: async ({ options }) =>
         {
            const djangoToken = localStorage.getItem('djangoToken');
            if (djangoToken)
            {
               options.headers.Authorization = `Token ${djangoToken}`
            }

            return options
         }
      }
   }

   return (
      <Provider url={process.env.REACT_APP_BASE_API_URL} options={options}>{children}</Provider>
   )

}

HttpProvider.propTypes = {
   children: PropTypes.node
};

export default HttpProvider;