import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';
import QuickView from 'components/QuickView';
import { useFetch } from 'use-http';

const quickViewContext = createContext();

export function useQuickView()
{
   return useContext(quickViewContext);
}

export function QuickViewCore({ children })
{
   //inizializzo l'oggetto per la chiamata fetch
   const fetcher = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });
   //inizializzo lo stato nel quale salvare l'elemento che fa da anchor alla quick view
   const [popperAnchor, setPopperAnchor] = useState(null);
   //inizializzo lo stato nel quale salvare il dato dell'operatore da visualizzare nella quick view
   const [operatoreData, setOperatoreData] = useState({});
   //inizializzo lo stato nel quale salvare una cache dei dati degli operatori per la quick view
   const [cacheOperatori, setCacheOperatori] = useState([]);

   const quickViewOpen = Boolean(popperAnchor);

   const showQuickView = async (el, idOperatore, params = null) =>
   {

      //controllo se ho già i dati di questo operatore nella cache
      const inCache = cacheOperatori.find(item => item?.id === idOperatore);
      
      console.log('inCache', inCache);
      //se li ho, restituisco i dati recuperati dalla cache e visualizzo il popper
      setPopperAnchor(el);
      if (inCache)
      {
         setOperatoreData(inCache);
         return;
      }
      const querystring = params ? '?' + new URLSearchParams(params).toString() : '';
      //eseguo fetch dei dati
      const data = await fetcher.request.get(`/api/anagrafiche/operatori/${idOperatore}/${querystring}`);
      //metto i dati in cache e imposto i dati operatore selezionato
      if( fetcher.error )
      {
         console.log('ERRORE');
         return;
      }

      if (fetcher.response.ok)
      {
         setCacheOperatori((prevState) => [...prevState, data]);
         setOperatoreData(data);
      }
   }

   const hideQuickView = () =>
   {
      fetcher.abort();
      setPopperAnchor(null);
      setOperatoreData(null);
   }

   return (
      <quickViewContext.Provider value={
         {
            //metodi
            showQuickView,
            hideQuickView,
         }
      }>
         {children}
         <QuickView
            open={quickViewOpen}
            anchorEl={popperAnchor}
            placement="auto"
            onClose={() => hideQuickView()}
            sx={{
               zIndex: 1310,
               left: '-9999px',
               'position': 'fixed',
            }}
            modifiers={[
               {
                  name: "offset",
                  options: {
                     offset: [0, 10],
                  },
               }
            ]}
            loading={fetcher.loading}
            data={operatoreData}
         />
      </quickViewContext.Provider>
   );
}

QuickViewCore.propTypes = {
   children: PropTypes.node,
};