// ==============================|| OVERRIDES - TAB ||============================== //

export default function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-required .MuiFormLabel-asterisk': {
            color: theme.palette.error.main,
            fontWeight: 700
          }
        }
      }
    }
  };
}
