import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// scroll bar
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

//custom styles
import 'assets/third-party/custom.css';

// load mock apis
import '_api';

// project import
import App from './App';
import { store, persister } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import { BeeCore } from 'contexts/Bee';
import GlobalDndContext from 'contexts/dnd-context';
import reportWebVitals from './reportWebVitals';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

ReactDOM.render(
   <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persister}>
         <ConfigProvider>
            <GlobalDndContext>
               <BrowserRouter basename="/app/">
                  <BeeCore>
                     <App />
                  </BeeCore>
               </BrowserRouter>
            </GlobalDndContext>
         </ConfigProvider>
      </PersistGate>
   </ReduxProvider>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
