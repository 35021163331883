/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation, Outlet } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetch } from 'use-http';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';

//component
import PageHeader from 'components/PageHeader';

//project import
import { openSnackbar, closeSnackbar } from 'store/reducers/snackbar';

//MUI
import
{
   Box,
   Divider,
   Skeleton,
   Tab,
   Tabs
} from '@mui/material'

import { LoadingButton } from '@mui/lab';

//FA Icons
import
{
   faFileLines,
   faShieldCheck,
   faTag,
   faMoneyCheckDollar,
   faUserSlash,
   faTrash,
   faFloppyDisk,
} from '@fortawesome/pro-regular-svg-icons';


const Contratto = () =>
{
   //recupero id operatore dai parametri url
   const { idContratto } = useParams();

   //recupero l'oggetto location
   const { pathname } = useLocation();

   //inizializzo hook dispatch
   const dispatch = useDispatch()

   const navigate = useNavigate();

   const {
      request,
      response,
      error,
   } = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   let selectedTab = 0;
   switch (true)
   {
      case /\/pianificazione\/contratti\/([0-9]*)\/dati-generali/g.test(pathname):
         selectedTab = 0;
         break;
      case /\/pianificazione\/contratti\/([0-9]*)\/servizi/g.test(pathname):
         selectedTab = 1;
         break;
      case /\/pianificazione\/contratti\/([0-9]*)\/prezzi/g.test(pathname):
         selectedTab = 2;
         break;
      case /\/pianificazione\/contratti\/([0-9]*)\/compensi/g.test(pathname):
         selectedTab = 3;
         break;
      case /\/pianificazione\/contratti\/([0-9]*)\/operatori-non-graditi/g.test(pathname):
         selectedTab = 4;
         break;
      default:
         selectedTab = 0;
   }

   //inizializzo gli status
   const [tab, setTab] = useState(selectedTab);
   const [success, setSuccess] = useState(null);
   const [deleting, setDeleting] = useState(false);

   //gestione tab attivo
   const handleTab = (event, newValue) =>
   {
      setTab(newValue);
   };

   const methods = useForm({
      defaultValues: async () => await request.get(`/api/pianificazione/contratti/${idContratto}/`)
   });

   //imposto l'oggetto con i links pre la breadcrumbs
   const breadcrumbs = useMemo(() =>
   {
      return [
         {
            label: "Pianificazione",
         },
         {
            label: "Contratti",
            path: "/pianificazione/contratti"
         },
         {
            label: `Contratto #${idContratto}`
         }
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [methods.formState.defaultValues?.titolo, methods.formState.isLoading]);

   useEffect(() => 
   {
      if (success !== null)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: success?.message,
               variant: 'alert',
               alert: {
                  color: 'success'
               },
               close: false
            })
         );
      }

      return () =>
      {
         setSuccess(null);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [success]);

   useEffect(() => 
   {
      if (methods.formState.errors.length)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: "Si è verificato un errore di validazione. Controlla i campi contrassegnati e riprova",
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      return () =>
      {
         methods.clearErrors();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [methods.formState.errors.length]);

   useEffect(() => 
   {
      if (error)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: `Si è verificato un errore (${error?.message})`,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [error]);

   const defaultFields = useMemo(() =>
   {
      return {
         agente: '',
         annotazioni: '',
         attivo: false,
         allarme: '',
         azienda_app: '',
         azienda_ese: '',
         cliente: '',
         data_disattivazione: '',
         id: 0,
         notifica: '',
         responsabile: '',
         sede: '',
         sono_vigile: '0',
         subagente: '',
         tipo_contratto: 'Giornaliero',
         numero_contratto: '',
         tipo_servizio: '',
         titolo: ''
      }
   }, []);

   const handleDeleteContratto = async () =>
   {

      if (window.confirm('Sei sicuro di voler eliminare questo contratto?\x0Dl\x27operazione non potrà essere annullata'))
      {
         setDeleting(true);

         const deleted = await request.delete(`/api/pianificazione/contratti/${idContratto}/`);

         if (response.ok)
         {
            navigate(`/pianificazione/contratti/`);
         }
      }

   }

   const onSubmit = async (formData, event) =>
   {

      const container = {
         data: formData
      }

      Object.keys(formData).forEach(key =>
      {
         switch (formData[key])
         {
            case '':
               formData[key] = null;
               break;
            default:
               break;
         }
      });

      const updated = await request.put(`/api/pianificazione/contratti/${idContratto}/`, container);

      if (response.ok)
      {
         methods.reset(updated);
         setSuccess({
            message: 'Modifiche salvate correttamente'
         });
      }

   }

   const StyledTab = styled(Tab)(() => ({
      lineHeight: 1
   }));

   return (
      <>
         <FormProvider {...methods}>
            <PageHeader
               title={methods.formState.isLoading ? <Skeleton width={150} /> : methods.formState.defaultValues?.titolo}
               afterTitle={methods.formState.isLoading ? <Skeleton width={100} /> : methods.formState.defaultValues?.cliente?.ragione_sociale}
               breadcrumbs={breadcrumbs}
            />
            <Box
               sx={{
                  mb: 3
               }}
            >
               <Tabs
                  value={tab}
                  onChange={handleTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="Contratto">
                  <StyledTab
                     component={RouterLink}
                     label="Dati generali"
                     to={`/pianificazione/contratti/${idContratto}/dati-generali`}
                     icon={<FontAwesomeIcon icon={faFileLines} />}
                     iconPosition="start"
                  />
                  <StyledTab
                     component={RouterLink}
                     label="Servizi"
                     to={`/pianificazione/contratti/${idContratto}/servizi`}
                     icon={<FontAwesomeIcon icon={faShieldCheck} />}
                     iconPosition="start"
                  />
                  <StyledTab
                     component={RouterLink}
                     label="Prezzi"
                     to={`/pianificazione/contratti/${idContratto}/prezzi`}
                     icon={<FontAwesomeIcon icon={faTag} />}
                     iconPosition="start"
                  />
                  <StyledTab
                     component={RouterLink}
                     label="Compensi"
                     to={`/pianificazione/contratti/${idContratto}/compensi`}
                     icon={<FontAwesomeIcon icon={faMoneyCheckDollar} />}
                     iconPosition="start"
                  />
                  <StyledTab
                     component={RouterLink}
                     label="Operatori non graditi"
                     to={`/pianificazione/contratti/${idContratto}/operatori-non-graditi`}
                     icon={<FontAwesomeIcon icon={faUserSlash} />}
                     iconPosition="start"
                  />
               </Tabs>
               <Divider
                  sx={{
                     borderColor: '#eee'
                  }}
               />
            </Box>
            <Box>
               <Outlet />
            </Box>
            <Box sx={{
               mt: 3,
               position: 'sticky',
               bottom: 0,
               zIndex: 100,
               backgroundColor: '#fafafb',
               py: 2
            }} textAlign="right">
               <LoadingButton
                  variant="contained"
                  color="error"
                  startIcon={<FontAwesomeIcon icon={faTrash} />} sx={{ mr: 2 }}
                  loadingPosition="start"
                  onClick={handleDeleteContratto}
                  loading={deleting}
               >
                  Elimina
               </LoadingButton>
               <LoadingButton
                  color="primary"
                  variant="contained"
                  startIcon={<FontAwesomeIcon icon={faFloppyDisk} />} size="large"
                  loadingPosition="start"
                  onClick={methods.handleSubmit(onSubmit)}
                  loading={methods.formState.isSubmitting}
               >
                  Salva le modifiche
               </LoadingButton>
            </Box>
         </FormProvider>
      </>
   )
}

export default Contratto;