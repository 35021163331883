import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import { useFetch } from 'use-http';

// constant
const initialState = {
   isLoggedIn: false,
   isInitialized: false,
   user: null
};

const setSession = (djangoToken) =>
{
   if (djangoToken)
   {
      localStorage.setItem('djangoToken', djangoToken);
   } else
   {
      localStorage.removeItem('djangoToken');
   }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const BeeAuthContext = createContext(null);

export const BeeAuthProvider = ({ children }) =>
{
   const [state, dispatch] = useReducer(authReducer, initialState);

   const { request, response } = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   const navigate = useNavigate();

   useEffect(() =>
   {
      const init = async () =>
      {
         try
         {
            const djangoToken = window.localStorage.getItem('djangoToken');
            if (djangoToken)
            {
               setSession(djangoToken);
               const user = await request.get(`/api/auth/account/`);

               dispatch({
                  type: LOGIN,
                  payload: {
                     isLoggedIn: true,
                     user
                  }
               });
            } else
            {
               dispatch({
                  type: LOGOUT
               });
            }
         } catch (err)
         {
            console.error(err);
            dispatch({
               type: LOGOUT
            });
         }
      };

      init();
   }, [request]);

   const login = async (username, password) =>
   {
      try{
         const user = await request.post(`/api/auth/login/`, { username: username, password: password });

         if (!response.ok)
         {
            console.log('response', response);
            throw new Error(response.data.detail || 'Login failed');
         }

         if( user?.token )
         {
            setSession(user?.token);
            dispatch({
               type: LOGIN,
               payload: {
                  isLoggedIn: true,
                  user
               }
            });
         }
      }catch(error)
      {
        
         alert(error.message);
      }
   };

   const register = async (email, password, firstName, lastName) =>
   {
      // todo: this flow need to be recode as it not verified
      const id = chance.bb_pin();
      const response = await request.post(`/api/auth/register/`, {
         id,
         email,
         password,
         firstName,
         lastName
      });

      let users = response.data;

      if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null)
      {
         const localUsers = window.localStorage.getItem('users');
         users = [
            ...JSON.parse(localUsers),
            {
               id,
               email,
               password,
               name: `${firstName} ${lastName}`
            }
         ];
      }

      window.localStorage.setItem('users', JSON.stringify(users));
   };

   const logout = async () =>
   {
      setSession(null);
      dispatch({ type: LOGOUT });
      navigate('/login');
   };

   const resetPassword = async (username) => {

      const res = await request.post(`/api/auth/reset-password/`, { username: username, redirect_url: `${process.env.REACT_APP_BASE_API_URL}/app/reset-password` });
      
      if( response.ok )
      {
         return res;
      }

   };

   const currentUserCan = (capabilities) => {

      const permissions = state.user && state.isLoggedIn ? state.user.permissions : [];
      return capabilities.some((perm) => permissions.includes(perm));

   }

   const updateProfile = () => { };

   if (state.isInitialized !== undefined && !state.isInitialized)
   {
      return <Loader />;
   }

   return <BeeAuthContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile, currentUserCan }}>{children}</BeeAuthContext.Provider>;
};

BeeAuthProvider.propTypes = {
   children: PropTypes.node
};

export default BeeAuthContext;
