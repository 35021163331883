//project import
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

//MUI
import
{
   Avatar,
   Box,
   Chip,
   Divider,
   Popper,
   Rating,
   Skeleton,
   Stack,
   Table,
   TableBody,
   TableCell,
   TableRow,
   Typography,
   Grid,
} from "@mui/material";

//components
import MainCard from "./MainCard";

//icons
import
{
   faMobileNotch,
} from '@fortawesome/pro-regular-svg-icons';

const QuickView = (props) =>
{

   const {
      data,
      loading,
      ...others
   } = props;

   const getTableContents = () =>
   {
      const contentArray = [];

      if (data?.ore_cli_week)
      {
         contentArray.push(
            <TableRow key={'ore_cli_week'}>
               <TableCell
                  align="left"
                  width="40%"
                  sx={{
                     verticalAlign: 'top',
                     '&.MuiTableCell-root:first-of-type': {
                        paddingRight: 1
                     }
                  }}
               >
                  <Typography
                     variant="body2"
                     component="span"
                     color="gray"
                  >Ore su cliente (sett.):
                  </Typography>
               </TableCell>
               <TableCell align="right">
                  {
                     data?.ore_cli_week.slice(0, -3)
                  }
               </TableCell>
            </TableRow>
         );
      }

      if (data?.ore_week)
      {
         contentArray.push(
            <TableRow key={'ore_week'}>
               <TableCell
                  align="left"
                  sx={{
                     verticalAlign: 'top',
                     '&.MuiTableCell-root:first-of-type': {
                        paddingRight: 1
                     }
                  }}
               >
                  <Typography
                     variant="body2"
                     component="span"
                     color="gray"
                  >Ore settimanali:
                  </Typography>
               </TableCell>
               <TableCell align="right">
                  {
                     data?.ore_week.slice(0, -3)
                  }
               </TableCell>
            </TableRow>
         );
      }

      if (data?.ore_month)
      {
         contentArray.push(
            <TableRow key={'ore_month'}>
               <TableCell
                  align="left"
                  sx={{
                     verticalAlign: 'top',
                     '&.MuiTableCell-root:first-of-type': {
                        paddingRight: 1
                     }
                  }}
               >
                  <Typography
                     variant="body2"
                     component="span"
                     color="gray"
                  >Ore mensili:
                  </Typography>
               </TableCell>
               <TableCell align="right">
                  {
                     data?.ore_month.slice(0, -3)
                  }
               </TableCell>
            </TableRow>
         );
      }

      if (data?.posizioni_operatore && data?.posizioni_operatore.length > 0)
      {
         contentArray.push(
            <TableRow key={'posizioni_operatore'}>
               <TableCell
                  align="left"
                  sx={{
                     verticalAlign: 'top',
                     '&.MuiTableCell-root:first-of-type': {
                        paddingRight: 1
                     }
                  }}
               >
                  <Typography
                     variant="body2"
                     component="span"
                     color="gray"
                  >Qualifiche:
                  </Typography>
               </TableCell>
               <TableCell align="right">
                  {
                     data?.posizioni_operatore?.map(item =>
                     {
                        return item?.posizione?.descrizione;
                     }).join(', ')
                  }
               </TableCell>
            </TableRow>
         );
      }

      return contentArray;
   }

   return (
      <Popper
         {...others}
      >
         <MainCard
            sx={{
               boxShadow: '0px 1px 4px rgba(0,0,0,.2)'
            }}
            boxShadow
            contentSX={{
               px: 1.5,
               py: 1.5,
               '&:last-child': {
                  pb: 1.5
               },
               width: 360
            }}
         >
            <Grid container columnSpacing={2}>
               <Grid item xs={3}>
                  <Stack spacing={1}>
                     <Box
                        sx={{
                           position: 'relative',
                           aspectRatio: '1/1'
                        }}
                     >
                        <Avatar
                           variant="square"
                           src={data?.foto_primo_piano ? process.env.REACT_APP_BASE_API_URL + data?.foto_primo_piano : ''}
                           sx={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              left: 0,
                              top: 0
                           }}
                        />
                     </Box>
                     {data?.rating && (
                        <Box display="flex" justifyContent="center">
                           <Rating name="read-only" value={data?.rating} readOnly sx={{ fontSize: '14px' }} />
                        </Box>
                     )}
                  </Stack>
               </Grid>
               <Grid item xs={9}>
                  <Stack spacing={.5}>
                     <Box display="flex" justifyContent="space-between">
                        <Typography
                           variant="h6"
                           sx={{
                              fontWeight: 700
                           }}
                        >
                           {
                              loading ? <Skeleton width={100} /> : `${data?.cognome} ${data?.nome}`
                           }
                        </Typography>
                        {data?.applicazione && (
                           <FontAwesomeIcon icon={faMobileNotch} />
                        )}
                     </Box>
                     <Table
                        size="small"
                        dense="dense"
                        sx={{
                           '& td.MuiTableCell-root': {
                              fontSize: '.75rem',
                              p: 0,
                              borderBottom: 0
                           },
                           '& td.MuiTableCell-root:first-of-type': {
                              paddingLeft: 0
                           },
                           '& td.MuiTableCell-root:last-of-type': {
                              paddingRight: 0
                           }
                        }}
                     >
                        {
                           loading && (
                              <TableBody>
                                 <TableRow>
                                    <TableCell align="left">
                                       <Typography
                                          variant="body2"
                                          component="span"
                                          color="gray"
                                       >
                                          <Skeleton width={80} />
                                       </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                       <Skeleton width={100} />
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell align="left">
                                       <Typography
                                          variant="body2"
                                          component="span"
                                          color="gray"
                                       >
                                          <Skeleton width={100} />
                                       </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                       <Skeleton width={80} />
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell align="left">
                                       <Typography
                                          variant="body2"
                                          component="span"
                                          color="gray"
                                       >
                                          <Skeleton width={80} />
                                       </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                       <Skeleton width={100} />
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell align="left">
                                       <Typography
                                          variant="body2"
                                          component="span"
                                          color="gray"
                                       >
                                          <Skeleton width={100} />
                                       </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                       <Skeleton width={80} />
                                    </TableCell>
                                 </TableRow>
                              </TableBody>
                           )
                        }
                        {
                           !loading && (
                              <TableBody>
                                 {data?.altezza && (
                                    <TableRow>
                                       <TableCell align="left">
                                          <Typography
                                             variant="body2"
                                             component="span"
                                             color="gray"
                                          >Altezza:
                                          </Typography>
                                       </TableCell>
                                       <TableCell align="right">
                                          {`${data?.altezza} cm`}
                                       </TableCell>
                                    </TableRow>
                                 )}
                                 {data?.peso && (
                                    <TableRow>
                                       <TableCell align="left">
                                          <Typography
                                             variant="body2"
                                             component="span"
                                             color="gray"
                                          >Peso:
                                          </Typography>
                                       </TableCell>
                                       <TableCell align="right">
                                          {`${data?.peso} kg`}
                                       </TableCell>
                                    </TableRow>
                                 )}
                                 {data?.data_nascita && (
                                    <TableRow>
                                       <TableCell align="left">
                                          <Typography
                                             variant="body2"
                                             component="span"
                                             color="gray"
                                          >Data di nascita:
                                          </Typography>
                                       </TableCell>
                                       <TableCell align="right">
                                          {moment(data?.data_nascita, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                       </TableCell>
                                    </TableRow>
                                 )}
                                 {data?.cellulare && (
                                    <TableRow>
                                       <TableCell align="left">
                                          <Typography
                                             variant="body2"
                                             component="span"
                                             color="gray"
                                          >Cellulare:
                                          </Typography>
                                       </TableCell>
                                       <TableCell align="right">
                                          {data?.cellulare}
                                       </TableCell>
                                    </TableRow>
                                 )}
                                 {data?.ultimo_contratto && (
                                    <TableRow>
                                       <TableCell align="left">
                                          <Typography
                                             variant="body2"
                                             component="span"
                                             color="gray"
                                          >Ultimo contratto:
                                          </Typography>
                                       </TableCell>
                                       <TableCell align="right">
                                          {data?.ultimo_contratto}
                                       </TableCell>
                                    </TableRow>
                                 )}
                              </TableBody>
                           )
                        }
                     </Table>
                  </Stack>
               </Grid>
               <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#ccc', my: 1 }} />
               </Grid>
               <Grid item xs={12}>
                  <Table
                     size="small"
                     dense="dense"
                     sx={{
                        '& td.MuiTableCell-root': {
                           fontSize: '.75rem',
                           p: 0,
                           borderBottom: 0
                        },
                        '& td.MuiTableCell-root:first-of-type': {
                           paddingLeft: 0
                        },
                        '& td.MuiTableCell-root:last-of-type': {
                           paddingRight: 0
                        }
                     }}
                  >
                     <TableBody>
                        {getTableContents()}
                     </TableBody>
                  </Table>
               </Grid>
               {data?.disponibilita && (
                  <>
                     <Grid item xs={12}>
                        <Divider sx={{ borderColor: '#ccc', my: 1 }} />
                     </Grid>
                     <Grid item xs={12}>
                        <Stack
                           direction="row"
                           spacing={1}
                           justifyContent="space-between"
                           sx={{
                              textAlign: 'center',
                              fontSize: '.75rem',
                           }}
                        >
                           {
                              Object.keys(data?.disponibilita).map((key, index) =>
                              {
                                 return (
                                    <Chip
                                       key={key}
                                       label={key}
                                       variant={index != 1 ? 'outlined' : ''}
                                       color={data?.disponibilita[key] ? "success" : "error"}
                                       sx={{
                                          fontWeight: 700,
                                          flexGrow: 1
                                       }}
                                    />
                                 )
                              })
                           }
                        </Stack>
                     </Grid>
                  </>
               )}

            </Grid>
         </MainCard>
      </Popper>
   );
}

QuickView.propTypes = {
   data: PropTypes.object,
   loading: PropTypes.bool
};

export default QuickView;