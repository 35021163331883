import PropTypes from 'prop-types';
import { Controller } from "react-hook-form"

//MUI
import
{
   FormControl,
   FormHelperText,
   InputLabel,
   Select,
} from "@mui/material"

const FormSelect = (props) =>
{
   const {
      children,
      control,
      name,
      label,
      rules = {},
      multiple = false,
      defaultValue = multiple ? [] : '',
      formControlProps = {},
      inputLabelProps = {},
      onChange, 
      ...others
   } = props

   return <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) =>
      {
         return (
            <FormControl
               error={fieldState?.error ? true : undefined}
               {...formControlProps}
            >
               <InputLabel
                  shrink={true}
                  {...inputLabelProps}
                  sx={{
                     '&.MuiInputLabel-root.Mui-required .MuiFormLabel-asterisk': {
                        color: 'red',
                        fontWeight: 700
                     },
                     ...inputLabelProps.sx
                  }}
               >
                  {label}
               </InputLabel>
               <Select
                  onChange={(event, data) => {
                     onChange ? onChange(event, data, field) : field.onChange(event, data);
                  }}
                  onBlur={field.onBlur}
                  value={field.value || defaultValue}
                  name={field.name}
                  ref={field.ref}
                  multiple={multiple}
                  defaultValue={defaultValue}
                  {...others}
               >
                  {children}
               </Select>
               {
                  fieldState?.error && fieldState?.error.message && (
                     <FormHelperText>{fieldState?.error.message}</FormHelperText>
                  )
               }
            </FormControl>
         )
      }}
   />
}

FormSelect.propTypes = {
   children: PropTypes.node,
   control: PropTypes.object,
   name: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
   rules: PropTypes.object,
   multiple: PropTypes.bool,
   defaultValue: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   formControlProps: PropTypes.object,
   onChange: PropTypes.func,
   inputLabelProps: PropTypes.object
};

export default FormSelect;