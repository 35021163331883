/* eslint-disable react/prop-types */
import { Controller } from "react-hook-form"

//MUI
import
{
   Autocomplete,
   CircularProgress,
   TextField,
} from "@mui/material"

const FormAutocomplete = (props) =>
{
   const {
      control,
      options = [{ value: "", label: "" }],
      name,
      label,
      rules = {},
      defaultValue = "",
      onChange,
      InputLabelProps,
      loading,
      multiple = false,
      ...others
   } = props

   const altValue = multiple ? [] : '';

   return <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState }) =>
      {
         return (
            <Autocomplete
               multiple={multiple}
               options={options || []}
               onChange={(event, data) => {
                  console.log('event, data', event, data)
                  onChange ? onChange(event, data, field) : field.onChange(data);
               }}
               value={field.value || altValue}
               renderInput={(params) =>
               {
                  console.log('params', params);
                  return (
                     <TextField
                        {...params}
                        fullWidth
                        inputRef={ref}
                        error={fieldState?.error ? true : undefined}
                        helperText={fieldState?.error && fieldState?.error.message}
                        label={label}
                        placeholder="Inizia a digitare..."
                        InputProps={{
                           ...params.InputProps,
                           endAdornment: (
                              <>
                                 {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                 {params.InputProps.endAdornment}
                              </>
                           ),
                        }}
                        InputLabelProps={{
                           sx: {
                              '&.MuiInputLabel-root.Mui-required .MuiFormLabel-asterisk': {
                                 color: 'red',
                                 fontWeight: 700
                              },
                              ...InputLabelProps.sx
                           },
                           ...InputLabelProps
                        }}
                     />
                  )
               }}
               
               {...others}
            />
         )
      }}
   />
}

export default FormAutocomplete;