// material-ui
import { useTheme } from '@mui/material/styles';

import logoIconDark from 'assets/images/fifa-logo-icon-dark-mode.png';
import logoIcon from 'assets/images/fifa-logo-icon.png';
// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */
     <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="FIFA Security" width="30" />
  );
};

export default LogoIcon;
