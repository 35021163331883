/* eslint-disable no-unused-vars */
import { useRef, useState, useEffect } from 'react';
import { useNotifiche } from 'contexts/Notifiche';
import { useNavigate } from 'react-router';
import moment from 'moment';

// material-ui
import { useTheme } from '@mui/material/styles';
import
{
   Alert,
   Badge,
   Box,
   ClickAwayListener,
   Divider,
   Fade,
   List,
   ListItemButton,
   ListItemText,
   ListItemSecondaryAction,
   Paper,
   Popper,
   Stack,
   Tooltip,
   Typography,
   useMediaQuery
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

// assets
import { BellOutlined, CheckCircleOutlined } from '@ant-design/icons';

// sx styles
const avatarSX = {
   width: 36,
   height: 36,
   fontSize: '1rem'
};

const actionSX = {
   mt: '6px',
   ml: 1,
   top: 'auto',
   right: 'auto',
   alignSelf: 'flex-start',

   transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () =>
{
   const theme = useTheme();
   const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
   const { notifiche } = useNotifiche();

   const anchorRef = useRef(null);
   const [open, setOpen] = useState(false);

   const navigate = useNavigate();

   const handleToggle = () =>
   {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleClose = (event) =>
   {
      if (anchorRef.current && anchorRef.current.contains(event.target))
      {
         return;
      }
      setOpen(false);
   };

   const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
   const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

   const handleListItemClick = (value) =>
   {
      navigate('/console/ticket-supporto/');
   };

   const [fade, setFade] = useState(true);

   useEffect(() =>
   {
      const fadeInterval = setInterval(() =>
      {
         setFade((prevFade) => !prevFade);
      }, 500); // imposta l'intervallo in millisecondi per l'animazione di dissolvenza

      return () => clearInterval(fadeInterval);
   }, []);

   return (
      <Box sx={{
         flexShrink: 0,
         ml: 0.75
      }}>
         <Stack
            direction="row"
            spacing={0.5}
         >
            {
               notifiche?.length > 0 && (
                  <Fade in={fade} timeout={1000}>
                     <Alert
                        severity="error"
                        variant='filled'
                        icon={false}
                        onClick={handleToggle}
                     >
                        <Typography
                           variant="body2"
                           sx={{
                              fontWeight: 700
                           }}
                        >Attenzione. Ci sono {notifiche?.length || 0} notifiche non lette</Typography>
                     </Alert>
                  </Fade>
               )
            }
            <IconButton
               color="secondary"
               variant="light"
               sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
               aria-label="open profile"
               ref={anchorRef}
               aria-controls={open ? 'profile-grow' : undefined}
               aria-haspopup="true"
               onClick={handleToggle}
            >
               <Badge badgeContent={notifiche?.length || 0} color="primary">
                  <BellOutlined />
               </Badge>
            </IconButton>
         </Stack>
         <Popper
            placement={matchesXs ? 'bottom' : 'bottom-end'}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
               modifiers: [
                  {
                     name: 'offset',
                     options: {
                        offset: [matchesXs ? -5 : 0, 9]
                     }
                  }
               ]
            }}
         >
            {({ TransitionProps }) => (
               <Transitions type="fade" in={open} {...TransitionProps}>
                  <Paper
                     sx={{
                        boxShadow: theme.customShadows.z1,
                        width: '100%',
                        minWidth: 285,
                        maxWidth: 420,
                        [theme.breakpoints.down('md')]: {
                           maxWidth: 285
                        }
                     }}
                  >
                     <ClickAwayListener onClickAway={handleClose}>
                        <MainCard
                           title="Problemi"
                           elevation={0}
                           border={false}
                           content={false}
                        >
                           <List
                              component="nav"
                              sx={{
                                 p: 0,
                                 maxHeight: 400,
                                 overflowY: 'auto',
                                 '& .MuiListItemButton-root': {
                                    py: 0.5,
                                    '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                                    '& .MuiAvatar-root': avatarSX,
                                    '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                                 }
                              }}
                           >
                              {
                                 notifiche?.length === 0 && (
                                    <ListItemButton>
                                       <ListItemText
                                          primary={
                                             <Typography variant="h6">
                                                Nessun problema segnalato
                                             </Typography>
                                          }
                                       />
                                    </ListItemButton>
                                 )
                              }
                              {
                                 notifiche?.length > 0 && notifiche.map(notifica =>
                                 {
                                    return (
                                       <>
                                          <ListItemButton onClick={() => handleListItemClick(notifica)} selected={notifiche?.length > 0} key={notifica?.id}>
                                             <ListItemText
                                                primary={
                                                   <Typography variant="h6">
                                                      {notifica?.operatore.full_name}
                                                   </Typography>
                                                }
                                                secondary={moment(notifica?.data_ora, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
                                             />
                                             <ListItemSecondaryAction>
                                                <Typography variant="caption" noWrap>
                                                   {moment(notifica?.data_ora, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
                                                </Typography>
                                             </ListItemSecondaryAction>
                                          </ListItemButton>
                                          <Divider />
                                       </>
                                    )
                                 })
                              }
                           </List>
                        </MainCard>
                     </ClickAwayListener>
                  </Paper>
               </Transitions>
            )}
         </Popper>
      </Box>
   );
};

export default Notification;
