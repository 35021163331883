import PropTypes from 'prop-types';

//MUI
import
{
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Skeleton
} from '@mui/material';

const SkeletonTableRowMarkup = (props) =>
{
   const {
      rows,
      columns
   } = props;

   return Array.apply(null, { length: rows }).map((row, rowIndex) =>
   {
      return (
         <TableRow key={`skeleton-row-${rowIndex}`}>
            {
               Array.apply(null, { length: columns }).map((cell, cellIndex) =>
               {
                  return (
                     <TableCell key={`skeleton-cell-${cellIndex}`}>
                        <Skeleton />
                     </TableCell>
                  )
               })
            }
         </TableRow>
      )
   });
}

SkeletonTableRowMarkup.propTypes = {
   rows: PropTypes.number,
   columns: PropTypes.number
};

const SkeletonTable = (props) =>
{
   const {
      rows = 1,
      columns = 4,
      ...others
   } = props;

   return (
      <Table
         {...others}
      >
         <TableHead>
            <SkeletonTableRowMarkup rows={1} columns={columns} />
         </TableHead>
         <TableBody>
            <SkeletonTableRowMarkup rows={rows} columns={columns} />
         </TableBody>
      </Table>
   )
}

SkeletonTable.propTypes = {
   rows: PropTypes.number,
   columns: PropTypes.number
};

export default SkeletonTable;