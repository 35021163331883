import PropTypes from 'prop-types';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { TableProvider, useTableProvider } from 'contexts/TableProvider';
import { useFetch } from "use-http";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

// material-ui
import
{
   Alert,
   Box,
   AlertTitle,
   Button,
   CardContent,
   CircularProgress,
   Drawer,
   Grid,
   IconButton,
   MenuItem,
   OutlinedInput,
   Stack,
   TablePagination,
   TextField,
   Typography,
   TableFooter,
   TableRow,
   TableCell
} from '@mui/material';

//project import
import { openSnackbar } from 'store/reducers/snackbar';

//Components
import MainCard from 'components/MainCard';
import TableFilters from 'components/TableFilters';
import SkeletonTable from 'components/SkeletonTable';
import ResourceTable from 'components/ResourceTable';
import FormTextField from 'components/FormTextField';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
import FormDateField from 'components/FormDateField';
import FormAutocomplete from 'components/FormAutocomplete';

import province from "data/province-italia";

import
{
   faXmark
} from '@fortawesome/pro-regular-svg-icons';

/**
 * Generates the markup for the table footer.
 *
 * @param {Object} props - The props passed to the component.
 * @return {JSX.Element} The JSX element representing the table footer markup.
 */
const TableFooterMarkup = (props) =>
{
   return (
      <Stack
         direction="row"
      >
         <TablePagination
            {...props}
         />
      </Stack>
   )
}

const EventiMarkup = ({ addNewDrawer = false }) =>
{

   const {
      page,
      rowsPerPage,
      sortOrder,
      totalCount,
      onPageChange,
      onRowsPerPageChange,
      onSortChange,
      groupBy,
      onGroupByChange,
      loading,
      error,
      isFirstRender,
      forceReload,
      autoload,
      data
   } = useTableProvider();

   const dispatch = useDispatch();

   const navigate = useNavigate();

   //inizializzo l'oggetto per la chiamata fetch
   const fetcher = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   const defaultValues = useMemo(() =>
   {
      return {
         titolo: '',
         descrizione: '',
         data_inizio: null,
         data_fine: null,
         indirizzo: '',
         citta: '',
         prov: '',
         contatore: false,
         abilitato: false
      }
   }, []);

   const formEvento = useForm({
      defaultValues: defaultValues
   });

   const [success, setSuccess] = useState(null);
   const [drawers, setDrawers] = useState({
      addEvent: false,
   });
   const [loadingDependencies, setLoadingDependencies] = useState(false);
   const [viewers, setViewers] = useState([]);

   useEffect(() =>
   {
      if (addNewDrawer)
      {
         setDrawers({
            ...drawers, addEvent: true
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [addNewDrawer]);

   useEffect(() => 
   {
      if (success !== null)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: success?.message,
               variant: 'alert',
               alert: {
                  color: 'success'
               },
               close: false
            })
         );
      }

      return () =>
      {
         setSuccess(null);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [success]);

   //gestisco gli errori dell'oggetto fetcher
   useEffect(() => 
   {
      if (fetcher.error)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: fetcher.error?.message,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fetcher.error]);

   const handleViewersAutocompleteOpen = useCallback(async () =>
   {
      if (viewers.length === 0)
      {
         setLoadingDependencies(true);

         const viewersData = await fetcher.request.get('/api/cms/utenti/?page_size=99999&tipologia_id=V');

         if (fetcher.error)
         {
            setLoadingDependencies(false);
            return [];
         }

         if (fetcher.response.ok)
         {
            setLoadingDependencies(false);
            let results = viewersData?.results || [];
            setViewers(results);
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [viewers.length]);

   const filters = useMemo(() =>
   {
      return {
         fields: [
            {
               type: 'globalSearch',
               label: 'Ricerca globale',
               visible: true,
               id: 'globalsearch'
            },
            {
               type: 'text',
               label: 'Evento',
               visible: true,
               name: 'descrizione',
               id: 'descrizione',
            },
            {
               type: 'text',
               label: 'Città',
               visible: true,
               name: 'citta',
               id: 'citta',
            },
            {
               type: 'date',
               label: 'Inizio',
               visible: true,
               name: 'data_inizio',
               id: 'data_inizio',
            },
            {
               type: 'date',
               label: 'Fine',
               visible: true,
               name: 'data_fine',
               id: 'data_fine',
            }
         ]
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   //creo l'oggetto columns per gestire le colonne della tabella
   const columns = useMemo(
      () => [
         {
            Header: 'Evento',
            accessor: 'titolo',
            sx: {
               fontWeight: 700
            }
         },
         {
            Header: 'Location',
            accessor: 'indirizzo',
         },
         {
            Header: 'Città',
            accessor: 'citta',
            sortable: true
         },
         {
            Header: 'Provincia',
            sortable: true,
            accessor: 'prov'
         },
         {
            Header: 'Inizio',
            accessor: 'data_inizio',
            Cell: ({ value }) =>
            {
               return moment(value, 'YYYY-MM-DD').format("DD/MM/YYYY");
            }
         },
         {
            Header: 'Fine',
            accessor: 'data_fine',
            Cell: ({ value }) =>
            {
               return moment(value, 'YYYY-MM-DD').format("DD/MM/YYYY");
            }
         }
      ],
      // eslint-disable-next-line
      []
   );

   //imposto l'ordine iniziale delle colonne della tabella
   const initialState = useMemo(
      () => ({
         columnOrder: [
            'titolo',
            'indirizzo',
            'citta',
            'prov',
            'data_inizio',
            'data_fine'
         ]
      }),
      []
   );

   const HelperTextErrorStyle = useMemo(() =>
   {
      return {
         mx: 0
      }
   }, []);

   const onAddEvento = async (formData) =>
   {
      setSuccess(null);

      const container = {
         data: formData
      }

      const added = await fetcher.request.post(`/api/cms/eventi/`, container);

      console.table(added);

      if (fetcher.error)
      {
         return;
      }

      if (fetcher.response.ok)
      {
         setSuccess({
            message: 'Evento aggiunto correttamente'
         });
         setDrawers({ ...drawers, addEvent: false });
         forceReload();
      }
   }

   const handleRowClick = (event, item) =>
   {
      navigate(`/cms/eventi/${item?.id}`);
   }

   return (
      <>
         <MainCard
            content={false}
            title='Lista eventi'
            secondary={loading && !isFirstRender && <CircularProgress color='inherit' size={16} sx={{ ml: 2 }} />}
         >
            <Stack
               sx={{
                  px: 2.75,
                  pt: 2.75
               }}
            >
               <TableFilters
                  filters={filters}
                  disable={loading}
               />
            </Stack>
            <CardContent
               sx={{
                  px: 0,
                  '&:last-child': {
                     pb: 0
                  }
               }}
            >
               {
                  error && (
                     <Alert
                        severity="error"
                     >
                        <AlertTitle
                           sx={{
                              fontWeight: 700
                           }}
                        >
                           Errore server
                        </AlertTitle>
                        Errore server<br />
                        Si è verificato un problema durante il recupero dei dati (Error: {error})
                     </Alert>
                  )
               }
               {
                  loading && !data?.results && (
                     <SkeletonTable rows={5} columns={6} />
                  )
               }
               {
                  !autoload && (
                     <Alert
                        severity="info"
                     >
                        <AlertTitle
                           sx={{
                              fontWeight: 700
                           }}
                        >
                           Attenzione
                        </AlertTitle>
                        Inizia impostando alcuni filtri per poter visualizzare risultati
                     </Alert>
                  )
               }
               {
                  data?.results && (
                     <ResourceTable
                        columns={columns}
                        data={data}
                        initialState={initialState}
                        orderAsc={sortOrder}
                        onSortChange={onSortChange}
                        groupByField={groupBy}
                        onGroupByChange={onGroupByChange}
                        onRowClick={handleRowClick}
                        //onCellClick={handleCellClick}
                        footer={
                           <TableFooter>
                              <TableRow>
                                 <TableCell colSpan={columns?.length}>
                                    <Stack
                                       direction={'row'}
                                       justifyContent={'flex-start'}
                                       alignItems={'center'}
                                    >
                                       <TableFooterMarkup
                                          page={!totalCount || totalCount <= 0 ? 0 : page}
                                          count={totalCount}
                                          onPageChange={onPageChange}
                                          rowsPerPageOptions={[10, 25, 50, 100]}
                                          component='div'
                                          rowsPerPage={rowsPerPage}
                                          onRowsPerPageChange={onRowsPerPageChange}
                                          labelRowsPerPage='Risultati per pagina'
                                          labelDisplayedRows={({ from, to, count }) =>
                                          {
                                             return `${from}–${to} di ${count !== -1 ? count : `altre ${to}`}`;
                                          }}
                                          SelectProps={{
                                             name: "page_size"
                                          }}
                                          sx={{
                                             '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                                                marginTop: 0,
                                                marginBottom: 0
                                             },
                                             '& .MuiToolbar-root': {
                                                paddingLeft: 0
                                             }
                                          }}
                                       />
                                       {
                                          loading && !isFirstRender && <CircularProgress color='inherit' size={16} sx={{ ml: 2 }} />
                                       }
                                    </Stack>
                                 </TableCell>
                              </TableRow>
                           </TableFooter>

                        }
                     />
                  )
               }
            </CardContent>
         </MainCard>
         <Drawer
            anchor='right'
            open={drawers.addEvent}
            onClose={() => setDrawers({ ...drawers, addEvent: false })}
            sx={{ zIndex: 1300 }}
            SlideProps={{
               onExited: () =>
               {
                  setDrawers({ ...drawers, addEvent: false });
                  formEvento.reset(defaultValues);
               }
            }}
         >
            <Box sx={{
               width: '350px',
               p: '2rem'
            }}>
               <Stack justifyContent="space-between" direction="row">
                  <Typography variant="h4">
                     Aggiungi Evento
                  </Typography>
                  <IconButton
                     onClick={() => setDrawers({ ...drawers, addEvent: false })}
                  >
                     <FontAwesomeIcon icon={faXmark} />
                  </IconButton>
               </Stack>
               <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={2.75}
                  sx={{ mt: 1 }}
               >
                  <Grid item lg={12}>
                     <FormTextField
                        name="titolo"
                        control={formEvento.control}
                        size="small"
                        fullWidth
                        label="Titolo"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        InputProps={{
                           autoComplete: 'new-password',
                        }}
                        required
                        rules={
                           {
                              required: {
                                 value: true,
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        disabled={formEvento.formState.isSubmitting}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormTextField
                        name="descrizione"
                        control={formEvento.control}
                        size="small"
                        multiline
                        rows={4}
                        fullWidth
                        label="Descrizione"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        InputProps={{
                           autoComplete: 'new-password',
                        }}
                        required
                        rules={
                           {
                              required: {
                                 value: true,
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        disabled={formEvento.formState.isSubmitting}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormAutocomplete
                        control={formEvento.control}
                        name="visualizzatori"
                        size="small"
                        label="Visualizzatori"
                        autoComplete
                        multiple
                        loading={loadingDependencies}
                        loadingText={'Loading...'}
                        onOpen={handleViewersAutocompleteOpen}
                        isOptionEqualToValue={(option, value) =>
                        {
                           if (!value || !option)
                           {
                              return false;
                           }
                           return option.id === value.id;
                        }}
                        noOptionsText={'Nessun utente trovato'}
                        options={viewers}
                        ListboxProps={{
                           sx: {
                              p: 0
                           }
                        }}
                        InputLabelProps={{
                           shrink: true,
                        }}
                        getOptionLabel={(option) =>
                        {
                           return option ? `${option?.first_name} ${option?.last_name}` : ''
                        }}
                        renderOption={(props, option) => (
                           <Stack
                              component="li"
                              direction={"row"}
                              {...props}
                              sx={{
                                 '&.MuiStack-root': {
                                    justifyContent: 'space-between'
                                 }
                              }}
                           >
                              <Typography
                                 component={'span'}
                              >
                                 {option?.first_name} {option?.last_name}
                              </Typography>
                              <Typography
                                 component={'span'}
                                 color={(theme) => theme.palette.text.secondary}
                                 sx={{
                                    paddingLeft: 2
                                 }}
                              >
                                 {option?.cellulare}
                              </Typography>
                           </Stack>
                        )}
                        disabled={formEvento.formState.isSubmitting}
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              label="Visualizzatori"
                              variant="outlined"
                              InputLabelProps={{
                                 shrink: true,
                              }}
                           />
                        )}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormTextField
                        name="indirizzo"
                        control={formEvento.control}
                        size="small"
                        fullWidth
                        label="Indirizzo"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        InputProps={{
                           autoComplete: 'new-password',
                        }}
                        required
                        rules={
                           {
                              required: {
                                 value: true,
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        disabled={formEvento.formState.isSubmitting}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormTextField
                        name="citta"
                        control={formEvento.control}
                        size="small"
                        fullWidth
                        label="Città"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        InputProps={{
                           autoComplete: 'new-password',
                        }}
                        required
                        rules={
                           {
                              required: {
                                 value: true,
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        disabled={formEvento.formState.isSubmitting}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormSelect
                        control={formEvento.control}
                        name="prov"
                        size="small"
                        displayEmpty
                        label='Provincia'
                        input={<OutlinedInput notched label="Provincia" />}
                        rules={{
                           required: {
                              value: true,
                              message: "Questo campo è obbligatorio"
                           }
                        }}
                        formControlProps={
                           {
                              fullWidth: true
                           }
                        }
                        disabled={formEvento.formState.isSubmitting}
                     >
                        <MenuItem
                           value=""
                        >
                           <Typography
                              component="em"
                              sx={{
                                 color: '#999999'
                              }}
                           >Seleziona</Typography>
                        </MenuItem>
                        {
                           province.map((prov, index) =>
                           {
                              return (
                                 <MenuItem
                                    key={index}
                                    value={prov?.sigla}
                                 >
                                    {prov?.nome}
                                 </MenuItem>
                              );
                           })
                        }
                     </FormSelect>
                  </Grid>
                  <Grid item lg={12}>
                     <FormSwitch
                        name="contatore"
                        control={formEvento.control}
                        size="small"
                        color="success"
                        disabled={formEvento.formState.isSubmitting}
                        sx={{
                           ml: '11px'
                        }}
                        label={
                           <Typography
                              noWrap
                              variant="body2"
                              component="span"
                           >
                              Contatore su app
                           </Typography>
                        }
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormDateField
                        name="data_inizio"
                        size="small"
                        control={formEvento.control}
                        rules={
                           {
                              required: {
                                 value: true,
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        fullWidth
                        required
                        label="Data inizio"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        disabled={formEvento.formState.isLoading}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormDateField
                        name="data_fine"
                        type="date"
                        size="small"
                        control={formEvento.control}
                        rules={
                           {
                              required: {
                                 value: true,
                                 message: "Questo campo è obbligatorio"
                              }
                           }
                        }
                        fullWidth
                        required
                        label="Data fine"
                        FormHelperTextProps={{
                           sx: HelperTextErrorStyle
                        }}
                        InputLabelProps={{
                           shrink: true
                        }}
                        disabled={formEvento.formState.isLoading}
                     />
                  </Grid>
                  <Grid item lg={12}>
                     <FormSwitch
                        name="abilitato"
                        control={formEvento.control}
                        size="small"
                        color="success"
                        disabled={formEvento.formState.isSubmitting}
                        sx={{
                           ml: '11px'
                        }}
                        label={
                           <Typography
                              noWrap
                              variant="body2"
                              component="span"
                           >
                              Abilitato
                           </Typography>
                        }
                     />
                  </Grid>
                  <Grid item lg={12} align="right">
                     <Button sx={{
                        ml: 1
                     }}
                        size="small"
                        variant="contained"
                        onClick={formEvento.handleSubmit(onAddEvento)}
                        endIcon={formEvento.formState.isSubmitting && <CircularProgress color="inherit" size={20} />}
                     >
                        Aggiungi
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Drawer>
      </>
   )

}

EventiMarkup.propTypes = {
   value: PropTypes.any,
   row: PropTypes.object,
   addNewDrawer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};

/**
 * Renders the `EventiMarkup` component.
 *
 * @return {JSX.Element} The rendered `EventiMarkup` component.
 */
const Eventi = ({ addNewDrawer = false }) =>
{

   const path = '/api/cms/eventi/';

   return (
      <TableProvider
         path={path}
      >
         <EventiMarkup addNewDrawer={addNewDrawer} />
      </TableProvider>
   )
}

Eventi.propTypes = {
   addNewDrawer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.func])
};

export default Eventi;