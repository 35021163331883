import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
// render - sample page
const FormCandidatura = Loadable(lazy(() => import('pages/extra-pages/form-candidatura')));
// ==============================|| MAIN ROUTING ||============================== //

const EmbeddableRoutes = {
   path: '/',
   children: [
      {
         path: '/form-candidatura',
         element: (
            <FormCandidatura />
         )
      }
   ]
};

export default EmbeddableRoutes;
