import PropTypes from 'prop-types';

//MUI
import {
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   Typography
} from "@mui/material";

const SelectFilter = (props) =>
{
   const {
      label,
      name,
      id,
      visible,
      options,
      blank = true,
      value = '',
      ...otherProps
   } = props;

   return (
      <FormControl
         fullWidth
         variant="outlined"
         size="small"
      >
         {
            label && <InputLabel shrink>{label}</InputLabel>
         }
         <Select
            value={value}
            displayEmpty
            name={name}
            id={id}
            disabled={!visible}
            {...otherProps}
         >
         {
            blank && (
               <MenuItem value="">
                  <em>Seleziona</em>
               </MenuItem>
            )
         }
            
            {
               options?.length > 0 && (
                  options.map((option, index) =>
                  {
                     return (
                        <MenuItem
                           key={`select-option-${index}`}
                           value={option.value}
                           sx={{
                              flexDirection: 'column',
                              alignItems: 'flex-start'
                           }}
                        >
                           {option.label}
                           {
                              option?.secondary && (
                                 <Typography
                                 variant='body2'
                                 component={'div'}
                                 >
                                    {option.secondary}
                                 </Typography>
                              )
                           }
                        </MenuItem>
                     )
                  })
               )
            }
         </Select>
      </FormControl>
   )
}

SelectFilter.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   options: PropTypes.array,
   visible: PropTypes.bool,
   blank: PropTypes.bool,
   value: PropTypes.any
};

export default SelectFilter;