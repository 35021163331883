import PropTypes from 'prop-types';

//MUI
import {
   Button
} from "@mui/material";

const ButtonFilter = (props) =>
{
   const {
      label,
      visible,
      disabled,
      ...others
   } = props;

   return (
      <Button
         hidden={!visible}
         disabled={disabled || !visible}
         {...others}
      >
         {label}
      </Button>
   )
}

ButtonFilter.propTypes = {
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   visible: PropTypes.bool,
   disabled: PropTypes.bool
};

export default ButtonFilter;