import { useNavigate } from "react-router";
import { Stack, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFilePlus, faUserGroup, faUserPlus } from "@fortawesome/pro-regular-svg-icons";

//components
import PrivateElement from "components/PrivateElement";

const QuickActions = () =>
{
   const navigate = useNavigate();

   return (
      <Stack direction="row">
         <PrivateElement capabilities={['superadmin','admin','erp_admin']}>
            <Tooltip title="Operatori">
               <IconButton
                  onClick={() => navigate("/anagrafiche/operatori")}
               >
                  <FontAwesomeIcon icon={faUserGroup} />
               </IconButton>
            </Tooltip>
         </PrivateElement>
         <PrivateElement capabilities={['superadmin','admin','erp_admin']}>
            <Tooltip title="Nuovo operatore">
               <IconButton
                  onClick={() => navigate("/anagrafiche/operatori/aggiungi-nuovo")}
               >
                  <FontAwesomeIcon icon={faUserPlus} />
               </IconButton>
            </Tooltip>
         </PrivateElement>
         <PrivateElement capabilities={['superadmin','admin','erp_admin']}>
            <Tooltip title="Contratti">
               <IconButton
                  onClick={() => navigate("/pianificazione/contratti")}
               >
                  <FontAwesomeIcon icon={faFile} />
               </IconButton>
            </Tooltip>
         </PrivateElement>
         <PrivateElement capabilities={['superadmin','admin','erp_admin']}>
            <Tooltip title="Nuovo contratto">
               <IconButton
                  onClick={() => navigate("/pianificazione/contratti/aggiungi-nuovo")}
               >
                  <FontAwesomeIcon icon={faFilePlus} />
               </IconButton>
            </Tooltip>
         </PrivateElement>
      </Stack>
   )
};

export default QuickActions;
