import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//data
import province from "data/province-italia";
import MiniIconButton from 'components/MiniIconButton';

//MUI
import
   {
      FormControl,
      InputLabel,
      MenuItem,
      Select,
   } from "@mui/material";

import {
   faCircleXmark
} from '@fortawesome/pro-solid-svg-icons';

const ProvinceFilter = (props) =>
{
   const {
      label,
      name,
      id,
      visible,
      multiple = false,
      value = multiple ? [''] : '',
      resetValues,
      ...others
   } = props;

   console.log('PROPS', props);

   return (
      <FormControl
         fullWidth
         variant="outlined"
         size="small"
      >
         {
            label && <InputLabel shrink>{label}</InputLabel>
         }
         <Select
            displayEmpty
            name={name}
            id={id}
            value={value}
            endAdornment={
               multiple && value?.length > 0 && (
                  <MiniIconButton
                     onClick={resetValues}
                     sx={{
                        marginRight: '.75rem',
                     }}
                  >
                     <FontAwesomeIcon icon={faCircleXmark} />
                  </MiniIconButton>
               )
            }
            disabled={!visible}
            multiple={multiple}
            renderValue={(selected) =>
            {
               let output = selected;

               if( Array.isArray(selected) )
               {
                  const selection = province.filter((prov) =>
                  {
                     return selected.includes(prov?.sigla);
                  }).map(p => p?.nome).join(', ');

                  output = selection;
               }

               return output || <em>Seleziona</em>;
            }}
            {...others}
         >
            <MenuItem value={''} disabled selected>
               <em>Seleziona</em>
            </MenuItem>
            {
               province?.length && (
                  province.map(provincia =>
                  {
                     return (
                        <MenuItem
                           key={provincia.nome}
                           value={provincia.sigla}
                        >
                           {provincia.nome}
                        </MenuItem>
                     )
                  })
               )
            }
         </Select>
      </FormControl>
   )
}

ProvinceFilter.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   visible: PropTypes.bool,
   value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
   multiple: PropTypes.bool,
   resetValues: PropTypes.any
};

export default ProvinceFilter;