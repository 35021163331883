import PropTypes from 'prop-types';

//MUI
import {
   TextField
} from "@mui/material";


const TextFilter = (props) =>
{
   const {
      type,
      label,
      name,
      id,
      visible,
      value = '',
      ...others
   } = props;

   return (
      <div>
         <TextField
            fullWidth
            label={label}
            name={name}
            id={id}
            type={type}
            disabled={!visible}
            variant="outlined"
            size="small"
            value={value}
            {...others}
            InputLabelProps={{
               shrink: true,
            }}
         />
      </div>
   )
}

TextFilter.propTypes = {
   type: PropTypes.string,
   name: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   visible: PropTypes.bool,
   value: PropTypes.string,
   onDateChange: PropTypes.func,
};

export default TextFilter;