/* eslint-disable no-unused-vars */
import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import useAuth from 'hooks/useAuth';

// project import
import LoginRoutes from './LoginRoutes';
import BaseRoutes from './BaseRoutes';
import PianificazioneRoutes from './PianificazioneRoutes';
import AnagraficheRoutes from './AnagraficheRoutes';
import ConsoleRoutes from './ConsoleRoutes';
import ReportRoutes from './ReportRoutes';
import CmsRoutes from './CmsRoutes';
import EmbeddableRoutes from './EmbeddableRoutes';
// ==============================|| ROUTING RENDER ||============================== //

// export default function ThemeRoutes() {
//   return useRoutes([LoginRoutes, MainRoutes]);
// }

export default function ThemeRoutes()
{

   const {
      user
   } = useAuth();

   return useRoutes([
      BaseRoutes(user),
      LoginRoutes(),
      PianificazioneRoutes(user),
      AnagraficheRoutes(user),
      ConsoleRoutes(user),
      ReportRoutes(user),
      CmsRoutes(user),
      EmbeddableRoutes
   ]);
}
