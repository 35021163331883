/* eslint-disable no-unused-vars */
import React, { useContext, createContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from "use-http";
import useAuth from 'hooks/useAuth';
import { isEqual } from 'lodash';

const notificheContext = createContext();

export function useNotifiche()
{
   return useContext(notificheContext);
}

export function NotificheCore({ children })
{
   const fetcher = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });
   //const fetcher = useFetch('https://fifa-erp.free.mockoapp.net', { cachePolicy: 'no-cache' });

   const [reload, setReload] = useState(null);
   const [notifiche, setNotifiche] = useState([]);

   const {
      isLoggedIn
   } = useAuth();

   const reloadNotifiche = () =>
   {
      setReload({});
   }

   useEffect(() =>
   {
      const checkForNotifiche = async () =>
      {
         const results = await fetcher.get('/api/comunicazioni/problemi-attivi/');

         if (fetcher.error)
         {
            console.log('Problema durante il recupero dei dati per il controllo delle notifiche', fetcher.error);
            setReload(null);
            return;
         }

         if (fetcher.response.ok && !isEqual(results, notifiche))
         {
            setNotifiche(results);
         }

         setReload(null);
      }

      let interval = null;

      if (isLoggedIn)
      {
         if (reload === null)
         {
            interval = setInterval(() =>
            {
               checkForNotifiche();
            }, 60 * 2 * 1000);

            checkForNotifiche();
         } else
         {
            checkForNotifiche();
         }
      }

      return () =>
      {
         clearInterval(interval);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reload]);

   return (
      <notificheContext.Provider value={
         {
            notifiche,
            setNotifiche,
            reloadNotifiche
         }
      }>
         {children}
      </notificheContext.Provider>
   );
}

NotificheCore.propTypes = {
   children: PropTypes.node,
};