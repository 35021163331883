import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useCallback } from 'react';

//MUI
import
   {
      FormControl,
      IconButton,
      InputAdornment,
      InputLabel,
      OutlinedInput,
   } from "@mui/material";

//Icons
import
   {
      faMagnifyingGlass
   } from "@fortawesome/pro-solid-svg-icons";

const GlobalSearch = (props) =>
{

   const {
      label,
      name,
      id,
      visible,
      onGlobalSearchSubmit,
      ...others
   } = props;

   const [value, setValue] = useState('');

   const handleGlobalSearchKeyDown = useCallback((event) =>
   {
      if (event.key === 'Enter')
      {
         event.preventDefault();
         onGlobalSearchSubmit(event.target.value);
      }
   }, [onGlobalSearchSubmit]);

   return (
      <FormControl fullWidth size="small" sx={{
         '& .MuiOutlinedInput-root': {
            paddingRight: 0
         }
      }}>
         {
            label && <InputLabel shrink>{label}</InputLabel>
         }
         <OutlinedInput
            sx={{
               backgroundColor: '#fafafb',
            }}
            value={value}
            disabled={!visible}
            id={id}
            name={name}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleGlobalSearchKeyDown}
            endAdornment={
               <InputAdornment position="end">
                  <IconButton
                     sx={{ color: '#ccc' }}
                     onClick={() => onGlobalSearchSubmit(value)}
                  >
                     <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </IconButton>
               </InputAdornment>
            }
            {...others}
         />
      </FormControl>
   )

}

GlobalSearch.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   onSearchSubmit: PropTypes.func,
   visible: PropTypes.bool,
   onGlobalSearchSubmit: PropTypes.func,
   value: PropTypes.string,
};

export default GlobalSearch;