// third-party
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//icons
import
   {
      faGauge,
      faCalendarDays,
      faUsers
   } from '@fortawesome/pro-solid-svg-icons';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const cmsMenu = {
   id: 'cms',
   title: 'Crowd Management System',
   type: 'group',
   permissions: ['superadmin','admin','cms_admin','cms_view','cms_view_control_room'],
   children: [
      {
         id: 'dashboard-cms',
         title: 'Control Room',
         type: 'item',
         permissions: ['superadmin','admin','cms_admin','cms_view_control_room'],
         url: '/cms/control-room/eventi-in-corso/',
         icon: () => <FontAwesomeIcon icon={faGauge} size="lg" />,
      },
      {
         id: 'eventi',
         title: 'Eventi',
         type: 'item',
         permissions: ['superadmin','admin','cms_admin'],
         url: '/cms/eventi/',
         icon: () => <FontAwesomeIcon icon={faCalendarDays} size="lg" />,
      },
      {
         id: 'utenti',
         title: 'Utenti',
         type: 'item',
         permissions: ['superadmin','admin','cms_admin'],
         url: '/cms/utenti/',
         icon: () => <FontAwesomeIcon icon={faUsers} size="lg" />,
      }
   ]
};

export default cmsMenu;
