import PropTypes from 'prop-types';

//MUI
import
   {
      IconButton,
      Tooltip
   } from "@mui/material";

const ButtonFilter = (props) =>
{
   const {
      visible,
      disabled,
      icon,
      label,
      ...others
   } = props;

   return (
      <>
         {
            label && (
               <Tooltip
                  title={label}
                  placement="top"
               >
                  <IconButton
                     hidden={!visible}
                     disabled={disabled || !visible}
                     {...others}
                  >
                     {icon}
                  </IconButton>
               </Tooltip>
            )
         }
         {
            !label && (
               <IconButton
                  hidden={!visible}
                  disabled={disabled || !visible}
                  {...others}
               >
                  {icon}
               </IconButton>
            )
         }
      </>
   )
}

ButtonFilter.propTypes = {
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
   visible: PropTypes.bool,
   disabled: PropTypes.bool
};

export default ButtonFilter;