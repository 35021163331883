import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

import logoDark from 'assets/images/fifa-logo-dark-mode.png';
import logo from 'assets/images/fifa-logo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <img src={theme.palette.mode === 'dark' ? logoDark : logo} width="210" alt="Fifa Security Network" />
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
