import { styled } from '@mui/material/styles';

//MUI
import { IconButton } from '@mui/material';

//styled components
const MiniIconButton = styled(IconButton)(() => ({
   padding: 0,
   width: 22,
   height: 22
}));

export default MiniIconButton;