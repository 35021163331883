import PropTypes from 'prop-types';

//MUI
import
{
   Autocomplete,
   TextField,
} from "@mui/material";

const AutocompleteFilter = (props) =>
{
   const {
      label,
      name,
      id,
      visible,
      options = [],
      textFieldProps,
      value,
      ...others
   } = props;

   return (
      <Autocomplete
         options={options}
         name={name}
         id={id}
         value={value}
         disabled={!visible}
         {...others}
         renderInput={(params) =>
         {
            return (
               <TextField
                  {...params}
                  fullWidth
                  label={label}
                  placeholder="Inizia a digitare..."
                  {...textFieldProps}
               />
            )
         }}
         
      />
   )
}

AutocompleteFilter.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   options: PropTypes.array,
   visible: PropTypes.bool,
   value: PropTypes.any,
   textFieldProps: PropTypes.object
};

export default AutocompleteFilter;