import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';

//MUI
import
{
   FormControl,
   InputLabel,
   MenuItem,
   Select
} from "@mui/material";

const QualificationFilter = (props) =>
{

   const {user: {settings: {posizioni}}} = useAuth();

   const {
      label,
      name,
      id,
      visible,
      multiple = false,
      value = multiple ? [] : '',
      ...others
   } = props;

   return (
      <FormControl
         fullWidth
         variant="outlined"
         size="small"
         
      >
         {
            label && <InputLabel shrink>{label}</InputLabel>
         }
         <Select
            value={value}
            displayEmpty
            name={name}
            id={id}
            disabled={!visible}
            multiple={multiple}
            renderValue={(selected) =>
            {
               let output = selected;

               if( Array.isArray(selected) )
               {
                  const selection = posizioni.filter((posizione) =>
                  {
                     return selected.includes(posizione?.id);
                  }).map(p => p?.descrizione).join(', ');

                  output = selection;
               }

               return output || <em>Seleziona</em>;
            }}
            {...others}
         >
            {(multiple && (!value || value.length === 0)) ? (
               <MenuItem value={[]}>
                  <em>Seleziona</em>
               </MenuItem>
            ) : null}
            {
               (posizioni?.length) && (
                  posizioni.map(option =>
                  {
                     return (
                        <MenuItem
                           key={option.descrizione}
                           value={option.id}
                        >
                           {option.descrizione}
                        </MenuItem>
                     )
                  })
               )
            }

         </Select>
      </FormControl>
   )
}

QualificationFilter.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   options: PropTypes.object,
   visible: PropTypes.bool,
   value: PropTypes.string,
   multiple: PropTypes.bool
};

export default QualificationFilter;