import { useEffect, useMemo, lazy, Suspense } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'use-http';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { QuickViewCore } from "contexts/QuickViewProvider";
import { AssegnazioneAvanzataCore } from 'contexts/AssegnazioneAvanzataContext';
import { useBee } from 'contexts/Bee';
import moment from 'moment';

//component
import PageHeader from 'components/PageHeader';

//sections
const DettagliAttivita = lazy(() => import('./DettagliAttivita'));
const ListaOperatori = lazy(() => import('./ListaOperatori'));
const OperatoriAssegnati = lazy(() => import('./OperatoriAssegnati'));

//project import
import { openSnackbar, closeSnackbar } from 'store/reducers/snackbar';

//MUI
import
{
   Skeleton,
   Stack,
   Typography
} from '@mui/material'

const AssegnazioneAvanzata = () =>
{
   //recupero id operatore dai parametri url
   const {
      idContratto,
      idServizio,
      idGiorno,
      idTurno
   } = useParams();

   //inizializzo hook dispatch
   const dispatch = useDispatch()

   //inizializzo l'oggetto per la chiamata fetch
   const {
      request,
      response,
      error
   } = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   const methods = useForm({
      defaultValues: async () =>
      {
         return await request.get(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/`);
      }
   });

   const {
      reload,
   } = useBee();

   useEffect(() =>
   {

      const refetchData = async () =>
      {

         const data = await request.get(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/`);

         if (response.ok)
         {
            methods.reset(data);
         }

      }

      if (reload)
      {
         refetchData();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reload]);

   //imposto l'oggetto con i links pre la breadcrumbs
   const breadcrumbs = useMemo(() =>
   {
      return [
         {
            label: "Pianificazione",
         },
         {
            label: "Contratti",
            path: "/pianificazione/contratti"
         },
         {
            label: `Contratto #${idContratto}`,
            path: `/pianificazione/contratti/${idContratto}/servizi/`
         },
         {
            label: "Servizio",
            path: `/pianificazione/contratti/${idContratto}/${idServizio}/assegnazione-risorse/servizio`
         },
         {
            label: "Assegnazione avanzata"
         }
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [methods.formState.isLoading, methods.formState.defaultValues, idContratto, idServizio]);

   //gestisco gli alerts
   useEffect(() =>
   {
      closeSnackbar();

      if (error)
      {
         dispatch(
            openSnackbar({
               anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
               },
               open: true,
               message: `Si è verificato un errore (${error?.message})`,
               variant: 'alert',
               alert: {
                  color: 'error'
               },
               close: false
            })
         );
      }

      return () =>
      {
         closeSnackbar();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [error]);

   return (
      <>
         <PageHeader
            title="Assegnazione avanzata"
            breadcrumbs={breadcrumbs}
            afterTitle={methods.formState.isLoading ? <Skeleton width={230} /> : (
               <Typography
                  variant="body"
               >
                  <Typography
                     component="strong"
                     variant="body"
                  >
                     {`${methods.formState.defaultValues?.contratto?.cliente?.ragione_sociale} - ${methods.formState.defaultValues?.contratto?.titolo}`}
                  </Typography>

                  {` - ${moment(methods.formState.defaultValues?.giorno, 'YYYY-MM-DD').format('DD/MM/YYYY')} - turno ${moment(methods.formState.defaultValues?.inizio_f1, 'HH:mm:ss').format('HH:mm')}/${moment(methods.formState.defaultValues?.fine_f1, 'HH:mm:ss').format('HH:mm')}${methods.formState.defaultValues?.inizio_f2 && methods.formState.defaultValues?.fine_f2 ? ` - ${moment(methods.formState.defaultValues?.inizio_f2, 'HH:mm:ss').format('HH:mm')}/${moment(methods.formState.defaultValues?.fine_f2, 'HH:mm:ss').format('HH:mm')}` : ''}`}

               </Typography>
            )}
         />
         <FormProvider {...methods}>
            <QuickViewCore>

               <AssegnazioneAvanzataCore>
                  <Stack spacing={3}>
                     <Suspense fallback={''}>
                        <DettagliAttivita />
                     </Suspense>

                     <Suspense fallback={''}>
                        <ListaOperatori />
                     </Suspense>

                     <Suspense fallback={''}>
                        <OperatoriAssegnati />
                     </Suspense>
                  </Stack>
               </AssegnazioneAvanzataCore>

            </QuickViewCore>
         </FormProvider>
      </>
   )
}

export default AssegnazioneAvanzata;