import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';

//MUI
import
{
   FormControl,
   InputLabel,
   MenuItem,
   Select
} from "@mui/material";

const ValutazioniFilter = (props) =>
{

   const {user: {settings: {valutazioni}}} = useAuth();

   const {
      label,
      name,
      id,
      visible,
      value = '',
      ...others
   } = props;

   return (
      <FormControl
         fullWidth
         variant="outlined"
         size="small"
      >
         {
            label && <InputLabel shrink>{label}</InputLabel>
         }
         <Select
            value={value}
            displayEmpty
            name={name}
            id={id}
            disabled={!visible}
            {...others}
         >
            <MenuItem value="">
               <em>Seleziona</em>
            </MenuItem>
            {
               (valutazioni?.length) && (
                  valutazioni.map(option =>
                  {
                     return (
                        <MenuItem
                           key={option.descrizione}
                           value={option.id}
                        >
                           {option.descrizione}
                        </MenuItem>
                     )
                  })
               )
            }

         </Select>
      </FormControl>
   )
}

ValutazioniFilter.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
   options: PropTypes.object,
   visible: PropTypes.bool,
   value: PropTypes.string
};

export default ValutazioniFilter;